import FormField from "../../../../../../../../CommonComponents/FormField";
import InputSection from "../../../InputSection/InputSection";
import countryList from "react-select-country-list";

const GlobalForm = ({ brandDetails }) => {
  const countriesOptions = countryList()
    .getData()
    .map((country) => ({ label: country.label, value: country.label }));

  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 40 }}>
      <InputSection
        title="Primary Country"
        description="Select the primary country where your business operates."
        required
        rowGap={10}
        children={
          <FormField
            name="country"
            type="select"
            options={countriesOptions}
            customizedStyling={{ width: "50%" }}
            errorMessage
          />
        }
      />
      <InputSection
        title="Additional Countries"
        description="Select any additional countries to be included in the Goodie’s AEO Global analysis."
        rowGap={10}
        children={
          <FormField
            name="secondary_countries"
            type="select"
            options={countriesOptions}
            customizedStyling={{ width: "50%" }}
            errorMessage
            multiSelect
          />
        }
      />
    </div>
  );
};
export default GlobalForm;
