import { useField } from "formik";
import ErrorTypography from "./components/ErrorTypography/ErrorTypography";

const ErrorMessage = ({ name }) => {
  const [, meta] = useField(name);
  const errorMessage = meta.error;
  const displayError = errorMessage && meta.touched;

  return displayError && <ErrorTypography errorMessage={errorMessage} />;
};
export default ErrorMessage;
