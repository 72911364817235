import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../api";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyLLMRankingData, rankingsLables } from "../../consts/consts";
import ChartCard from "../ChartCard/ChartCard";
import RankingTypography from "../RankingTypography/RankingTypography";
import RankingWithProgressLine from "../RankingWithProgressLine/RankingWithProgressLine";
import TitlesGrid from "../TitlesGrid/TitlesGrid";

const RankingProgressCard = ({
  title,
  icon,
  tooltipPrimary,
  tooltipSecondary,
  height,
  hasLLMSelect,
  hasTypographyRanking,
  gridTitle2,
  gridTitle3,
  isPerformance,
}) => {
  const { date, llm, noData } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const { brandId } = useParams();
  const [data, setData] = useState([]);
  const currentWorkspaceName = localStorage.getItem("worskapceName");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = (url, values) => {
      setLoading(true);

      axios
        .post(url, values)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const commonValues = {
      brandId,
      comparsion_type: "Competitors",
      LLMs: selectedLLM,
    };

    if (!isPerformance) {
      fetchData(`${ML_AEO_URL}/aeo/brand-visibility-score-rank`, commonValues);
    } else if (date.length > 0 && date[0] && date[1]) {
      const values = {
        ...commonValues,
        start_date: date[0].format("YYYY-MM-DD"),
        end_date: date[1].format("YYYY-MM-DD"),
      };
      fetchData(`${ML_AEO_URL}/aeo/brand-performance-score-rank`, values);
    }
  }, [brandId, selectedLLM, date, isPerformance]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);
  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessagePrimary={tooltipPrimary}
      tooltipMessageSecondary={tooltipSecondary}
      height={height}
      hasLLMselect={hasLLMSelect}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      isLoading={loading}
      isError={error}
    >
      {hasTypographyRanking && (
        <RankingTypography
          ranking={
            rankingsLables[
              data?.findIndex((item) => item.label === currentWorkspaceName)
            ]
          }
        />
      )}
      <TitlesGrid
        title2={gridTitle2}
        title3={gridTitle3}
        grid1={1}
        grid3={4.5}
      />
      <RankingWithProgressLine rankings={noData ? dummyLLMRankingData : data} />
    </ChartCard>
  );
};
export default RankingProgressCard;
