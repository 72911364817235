import React, { useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "./Theme/ThemeContext";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import "./index.css";
import BlogWriter from "./pages/BlogWriter";
import Workspace from "./pages/Workspace";
import Layout from "./CommonComponents/Layout/Layout";
import Dashboard from "./pages/Dashboard";
import FinalBlog from "./pages/BlogWriter/components/FinalBlog/FinalBlog";
import BrandDnaOverview from "./pages/BrandDna/BrandDnaOverView";
import Accounts from "./pages/Accounts";
import Login from "./pages/Accounts/Components/Login/Login";
import SignUp from "./pages/Accounts/Components/SignUp";
import AccountWorkspace from "./pages/Accounts/Components/Workspace/Workspace";
import People from "./pages/Workspace/components/People";
import AuthorStamp from "./pages/AuthorStamp/AuthorStamp";
import KnowledgeBase from "./pages/Workspace/components/KnowledgeBase";
import NotFound404 from "./pages/NotFound404";
import { BlogProvider } from "./contexts/blogContext";
import AEODashboard from "./pages/AEO/Pages/Dashboard/Dashboard";
import VisibilityAnalysis from "./pages/AEO/Pages/VisibilityAnalysis/VisibilityAnalysis";
import PerformanceAnalysis from "./pages/AEO/Pages/PerformanceAnalysis/PerformanceAnalysis";
import SentimentAnalysis from "./pages/AEO/Pages/SentimentAnalysis/SentimentAnalysis";
import ComparisonAnalysis from "./pages/AEO/Pages/ComparisonAnalysis/ComparisonAnalysis";
import GlobalMonitoring from "./pages/AEO/Pages/GlobalMonitoring/GlobalMonitoring";
import ConnectAccounts from "./pages/Workspace/components/ConnectAccounts";
import GeneralSettings from "./pages/Workspace/components/GeneralSettings";
import { handleUserLogic } from "./pages/utils";
import PendingInvites from "./pages/Accounts/Components/Workspace/Components/PendingInvites";

const ProtectedRoute = ({ children }) => {
  const { brandId } = useParams();
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const userBrands = loggedInUser?.brands;
  const canAccessWorkspace = userBrands?.some(
    (brand) => brand.id === Number(brandId)
  );

  if (userBrands && canAccessWorkspace) {
    return children;
  } else if (!loggedInUser) {
    return <Navigate to="/login" />;
  } else {
    return <Navigate to="*" />;
  }
};

function App() {
  useEffect(() => {
    handleUserLogic();
  }, []);

  return (
    <BlogProvider>
      <ThemeProvider>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/accounts/:userId" element={<Accounts />} />
            <Route
              path="/accounts/:userId/pending-invites"
              element={<PendingInvites />}
            />

            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/workspace-setup" element={<AccountWorkspace />} />

            <Route path="/blog-writer" element={<BlogWriter />} />
            <Route
              path="/dashboard/:brandId/blog-writer"
              element={
                <ProtectedRoute>
                  <BlogWriter />
                </ProtectedRoute>
              }
            />

            <Route path="/" element={<Layout />}>
              <Route
                path="/dashboard/:brandId"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/workspace-settings/:brandId"
                element={
                  <ProtectedRoute>
                    <Workspace />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/workspace-settings/:brandId/people"
                element={
                  <ProtectedRoute>
                    <People />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/workspace-settings/:brandId/knowledge-base"
                element={
                  <ProtectedRoute>
                    <KnowledgeBase />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/workspace-settings/:brandId/brand-dna-overview"
                element={
                  <ProtectedRoute>
                    <BrandDnaOverview />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/workspace-settings/:brandId/connect-accounts"
                element={
                  <ProtectedRoute>
                    <ConnectAccounts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/workspace-settings/:brandId/general-settings"
                element={
                  <ProtectedRoute>
                    <GeneralSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard/:brandId/blog/:title"
                element={
                  <ProtectedRoute>
                    <FinalBlog />
                  </ProtectedRoute>
                }
              />
              <Route path="account/author-stamp" element={<AuthorStamp />} />

              {/*AEO*/}
              <Route
                path="ai-visibility/:brandId/dashboard"
                element={
                  <ProtectedRoute>
                    <AEODashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="ai-visibility/:brandId/visibility-analysis"
                element={
                  <ProtectedRoute>
                    <VisibilityAnalysis />
                  </ProtectedRoute>
                }
              />
              <Route
                path="ai-visibility/:brandId/performance-analysis"
                element={
                  <ProtectedRoute>
                    <PerformanceAnalysis />
                  </ProtectedRoute>
                }
              />
              <Route
                path="ai-visibility/:brandId/sentiment-analysis"
                element={
                  <ProtectedRoute>
                    <SentimentAnalysis />
                  </ProtectedRoute>
                }
              />
              <Route
                path="ai-visibility/:brandId/comparison-analysis"
                element={
                  <ProtectedRoute>
                    <ComparisonAnalysis />
                  </ProtectedRoute>
                }
              />
              <Route
                path="ai-visibility/:brandId/global-monitoring"
                element={
                  <ProtectedRoute>
                    <GlobalMonitoring />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path="*" element={<NotFound404 />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </BlogProvider>
  );
}

export default App;
