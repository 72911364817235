import React from "react";

const PerformanceIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "18px"}
    height={height ? height : "18px"}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path
      d="M4.00016 9.99984C3.82335 9.99984 3.65378 10.0701 3.52876 10.1951C3.40373 10.3201 3.3335 10.4897 3.3335 10.6665V12.6665C3.3335 12.8433 3.40373 13.0129 3.52876 13.1379C3.65378 13.2629 3.82335 13.3332 4.00016 13.3332C4.17697 13.3332 4.34654 13.2629 4.47157 13.1379C4.59659 13.0129 4.66683 12.8433 4.66683 12.6665V10.6665C4.66683 10.4897 4.59659 10.3201 4.47157 10.1951C4.34654 10.0701 4.17697 9.99984 4.00016 9.99984ZM6.66683 7.99984C6.49002 7.99984 6.32045 8.07008 6.19542 8.1951C6.0704 8.32012 6.00016 8.48969 6.00016 8.6665V12.6665C6.00016 12.8433 6.0704 13.0129 6.19542 13.1379C6.32045 13.2629 6.49002 13.3332 6.66683 13.3332C6.84364 13.3332 7.01321 13.2629 7.13823 13.1379C7.26326 13.0129 7.3335 12.8433 7.3335 12.6665V8.6665C7.3335 8.48969 7.26326 8.32012 7.13823 8.1951C7.01321 8.07008 6.84364 7.99984 6.66683 7.99984ZM12.0002 2.6665C11.8234 2.6665 11.6538 2.73674 11.5288 2.86177C11.4037 2.98679 11.3335 3.15636 11.3335 3.33317V12.6665C11.3335 12.8433 11.4037 13.0129 11.5288 13.1379C11.6538 13.2629 11.8234 13.3332 12.0002 13.3332C12.177 13.3332 12.3465 13.2629 12.4716 13.1379C12.5966 13.0129 12.6668 12.8433 12.6668 12.6665V3.33317C12.6668 3.15636 12.5966 2.98679 12.4716 2.86177C12.3465 2.73674 12.177 2.6665 12.0002 2.6665ZM9.3335 5.33317C9.15668 5.33317 8.98712 5.40341 8.86209 5.52843C8.73707 5.65346 8.66683 5.82303 8.66683 5.99984V12.6665C8.66683 12.8433 8.73707 13.0129 8.86209 13.1379C8.98712 13.2629 9.15668 13.3332 9.3335 13.3332C9.51031 13.3332 9.67988 13.2629 9.8049 13.1379C9.92992 13.0129 10.0002 12.8433 10.0002 12.6665V5.99984C10.0002 5.82303 9.92992 5.65346 9.8049 5.52843C9.67988 5.40341 9.51031 5.33317 9.3335 5.33317Z"
      fill={color}
    />
  </svg>
);

export default PerformanceIcon;
