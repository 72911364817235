import { Box, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../api";
import StepperModal from "../../CommonComponents/StepperModal";
import WorkspacePageTemplate from "../Workspace/components/PageTemplate/PageTemplate";
import BrandVoice from "./components/BrandDNAForm/components/BrandVoice/BrandVoice";
import Business from "./components/BrandDNAForm/components/Business";
import Competition from "./components/BrandDNAForm/components/Competition/Competition";
import Global from "./components/BrandDNAForm/components/Global";
import Persona from "./components/BrandDNAForm/components/Persona";
import BrandDNAOverviewCard from "./components/Card/Card";
import { getValidationSchema, steps } from "./conts";

const BrandDnaOverview = () => {
  const { brandId } = useParams();
  const brandOverview = JSON.parse(localStorage.getItem("workspace"));
  const [openModal, setOpenModal] = useState(false);
  const [brandDetails, setBrandDetails] = useState({});
  const [initialStep, setinitialStep] = useState(0);
  const companyName = localStorage.getItem("worskapceName") || "Workspace name";
  const [loading, setLoading] = useState(false);
  const [isAutoSave, setIsAutoSave] = useState(false);

  const cards = useMemo(
    () => [
      {
        isIncomplete: !brandOverview["business_step_completed"],
        title: "Business",
        step: 0,
      },
      {
        isIncomplete: !brandOverview["persona_step_completed"],
        title: "Persona",
        step: 1,
      },
      {
        isIncomplete: !brandOverview["brand_voice_step_completed"],
        title: "Brand Voice",
        step: 2,
      },
      {
        isIncomplete: !brandOverview["competitors_step_completed"],
        title: "Competition",
        step: 3,
      },
      {
        isIncomplete: !brandOverview["global_step_completed"],
        title: "Global",
        step: 4,
      },
    ],
    [brandOverview]
  );
  const getStepContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return <Business brandDetails={brandDetails} />;
        case 1:
          return <Persona brandDetails={brandDetails} />;

        case 2:
          return <BrandVoice brandDetails={brandDetails} />;
        case 3:
          return <Competition brandDetails={brandDetails} />;
        case 4:
          return <Global brandDetails={brandDetails} />;
        default:
          return <Business brandDetails={brandDetails} />;
      }
    },
    [brandDetails]
  );

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}`)
      .then((response) => {
        setBrandDetails(response.data);
        localStorage.setItem("worskapceName", response.data.name);
      })
      .catch((err) => {});
  }, [brandId]);

  const initialValues = useMemo(
    () => ({
      // business
      name: brandDetails?.name,
      primary_product: brandDetails?.primary_product,
      description: brandDetails?.description,
      conversion_goals: brandDetails?.conversion_goals || [],
      industry: brandDetails?.industry || "",
      business_model: brandDetails?.business_model || "",
      features: brandDetails?.features || ["", ""],
      benefits: brandDetails?.benefits || ["", ""],
      market_focus: brandDetails?.market_focus || "",
      usp: brandDetails.usp || "",
      country: brandDetails?.country || "",
      primary_language: brandDetails.primary_language || "",
      website: brandDetails?.website || "",
      additional_websites: brandDetails?.additional_websites || [""],
      topics: brandDetails?.topics || [""],
      market_attributes: brandDetails?.market_attributes || [""],
      comparison_attributes: brandDetails?.comparison_attributes || [""],
      secondary_countries: brandDetails?.secondary_countries || [],
      social_media_accounts_urls: brandDetails?.social_media_accounts_urls || [
        "",
      ],
      // persona
      age_ranges: brandDetails?.age_ranges || [],
      genders: brandDetails?.genders || [],
      interests: brandDetails?.interests || [],
      job_functions: brandDetails?.job_functions || [],
      job_titles: brandDetails?.job_titles || [],
      desires: brandDetails?.desires || ["", ""],
      pain_points: brandDetails?.pain_points || ["", ""],

      // brand voice
      brand_voice: brandDetails?.brand_voice || "",
      not_brand_voice: brandDetails?.not_brand_voice || "",
      formality_tone: brandDetails?.formality_tone || "",
      core_themes: brandDetails?.core_themes || [],
      emotions: brandDetails?.emotions || [],
      styles: brandDetails?.styles || [],

      // competitors
      competitors: brandDetails?.competitors || [{ name: "", website: "" }],
    }),
    // eslint-disable-next-line
    [brandId, brandDetails]
  );

  const handleSave = useCallback(
    (values) => {
      !isAutoSave && setLoading(true);
      axios
        .patch(`${API_URL}/brands/${brandId}`, values, {})
        .then((response) => {
          if (!isAutoSave) {
            setLoading(false);
            window.location.href = `/dashboard/${brandId}/`;
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [brandId, isAutoSave]
  );
  const theme = useTheme();
  return (
    <WorkspacePageTemplate
      title="Brand DNA"
      navigateTo={`/workspace-settings/${brandId}`}
    >
      <Typography
        variant="h3"
        sx={{
          fontSize: 14,
          mr: { md: 32 },
          mb: 5,
          fontWeight: 450,
          maxWidth: 508,
        }}
        color="text.secondary"
      >
        The Brand DNA is the foundation of your brand's personality, guiding
        every aspect of your communication and strategy. Define the core essence
        of your brand with the comprehensive four sections below. By clearly
        outlining your brand’s positioning in these sections, you can ensure a
        consistent and cohesive brand presence across all platforms.
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 450,
          lineHeight: "20px",
          borderTop: `1px solid ${theme.palette.border.tertiary}`,
          pt: "20px",
          mb: "20px",
        }}
        color="text.tertiary"
      >
        All Sections
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        {cards.map((card, index) => {
          return (
            <Box
              key={index}
              onClick={() => {
                setinitialStep(index);
                setOpenModal(true);
              }}
            >
              <BrandDNAOverviewCard card={card} />
            </Box>
          );
        })}
      </Box>
      <StepperModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        setOpenModal={setOpenModal}
        modalName="Brand DNA"
        title={companyName}
        steps={steps}
        initialStep={initialStep}
        getStepContent={getStepContent}
        formikChildren={(Business, Persona, BrandVoice, Competition)}
        initialValues={initialValues}
        getValidationSchema={getValidationSchema}
        handleSave={handleSave}
        loading={loading}
        setIsAutoSave={setIsAutoSave}
        isAutoSave={isAutoSave}
      />
    </WorkspacePageTemplate>
  );
};
export default BrandDnaOverview;
