import { Grid, useTheme } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import StackedBarCard from "../../components/StackedBarCard/StackedBarCard";
import RankingStackedBarCard from "../../components/RankingStackedBarCard/RankingStackedBarCard";
import RadarChartCard from "../../components/RadarChartCard/RadarChartCard";
import AverageLineCard from "../Dashboard/Components/AverageLineCard/AverageLineCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import SentimentIcon from "../../../../CommonComponents/customIcons/SentimentIcon";

const SentimentAnalysis = () => {
  const theme = useTheme();

  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="Sentiment Analysis"
        description="View user sentiment trends (positive, negative, neutral) across models and over time. Assess how public perception aligns with overall brand performance and market success."
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={3.8}>
            <AverageLineCard
              icon={<SentimentIcon color={theme.palette.text.primary} />}
              title="Average Sentiment Score"
              tooltipPrimary="Shows the sentiment score trend over the last 24 hours, calculated across ChatGPT, Gemini and Perplexity."
              tooltipSecondary="A score of 100 reflects the most positive brand sentiment."
              height={232}
              index={1}
              widget_type="Sentiment"
            />
          </Grid>{" "}
          <Grid item xs={7.9}>
            <RankingStackedBarCard
              icon={<SentimentIcon color={theme.palette.text.primary} />}
              title="Average Competitor Sentiment Score"
              tooltipPrimary="Compares sentiment distribution (positive, neutral, negative) across brands."
              tooltipSecondary="Scores total 100%"
              hasLLMSelect
              height={232}
              gridTitle2="Company"
              gridTitle3=""
            />
          </Grid>
        </Grid>

        <StackedBarCard
          icon={<SentimentIcon color={theme.palette.text.primary} />}
          title="Sentiment Distribution Trend"
          tooltipPrimary="Compares sentiment distribution (positive, neutral, negative) across brands."
          tooltipSecondary="Total score for each month add up to 100%"
          hasCalendarSelect
          hasLLMselect
          height={328}
          isVertical
          chartHeight={270}
          chartWidth={1000}
          barSize={240}
          isSentimentTimeSeries
          XAxisDataKey="xAxis"
        />

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={5.85}>
            <RadarChartCard
              icon={<VisibilityIcon color={theme.palette.text.primary} />}
              title="Competitive Analysis"
              tooltipPrimary="Evaluates your brand’s performance against competitors based on key industry criteria."
              height={500}
              comparsion_type="LLMs"
            />
          </Grid>
        </Grid>
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default SentimentAnalysis;
