import { Box, Grid, Typography } from "@mui/material";
import StackedBarChart from "../../../../CommonComponents/Charts/StackedBarChart/StackedBarChart";

const RankingWithStackedBar = ({ rankings }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: 7.5,
      }}
    >
      {rankings?.map((ranking, index) => (
        <Grid
          container
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={1}>
            <Typography
              style={{ fontWeeight: 400, fontSize: 14 }}
              color="text.tertiary"
            >
              {index + 1}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 4,
            }}
          >
            {/* {ranking.icon && (
              <img src={ranking.icon} style={{ width: 14, height: 14 }} />
            )} */}

            <Typography
              style={{ fontWeeight: 400, fontSize: 14 }}
              color="text.primary"
            >
              {ranking.label}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <StackedBarChart data={[ranking]} width={750} />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
export default RankingWithStackedBar;
