import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useFormikContext } from "formik";

export function AutoSave({ setIsAutoSave, isAutoSave, handleSave }) {
  const formik = useFormikContext();

  // Debounced save function
  const debouncedSave = useDebouncedCallback(() => {
    if (isAutoSave && formik.dirty) {
      // Get all valid and touched fields
      const validTouchedFields = Object.keys(formik.touched).filter(
        (field) => formik.errors[field] === undefined && formik.touched[field]
      );

      if (validTouchedFields.length > 0) {
        handleSave(formik.values);
      }
    }
  }, 1000);

  useEffect(() => {
    // Check if any touched fields are valid and dirty
    const validTouchedFields = Object.keys(formik.touched).filter(
      (field) => formik.errors[field] === undefined && formik.touched[field]
    );

    // Trigger auto-save if there are valid and touched fields
    if (validTouchedFields.length > 0 && formik.dirty) {
      setIsAutoSave(true);
      debouncedSave();
    }
  }, [
    formik.values,
    formik.dirty,
    formik.touched,
    formik.errors,
    formik.isSubmitting,
    debouncedSave,
    setIsAutoSave,
  ]);

  return null;
}
