import { createContext, useContext, useEffect, useState } from "react";

const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [editedBlog, setEditedBlog] = useState(() => {
    return localStorage.getItem("editedBlog")
      ? JSON.parse(localStorage.getItem("editedBlog"))
      : "";
  });

  useEffect(() => {
    localStorage.setItem("editedBlog", JSON.stringify(editedBlog));
  }, [editedBlog]);
  return (
    <BlogContext.Provider value={{ editedBlog, setEditedBlog }}>
      {children}
    </BlogContext.Provider>
  );
};

export const useBlog = () => useContext(BlogContext);
