import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";

const RankingTypography = ({ ranking }) => {
  return (
    <Box>
      <Typography
        color="text.primary"
        style={{ fontSize: 30, fontWeight: 450 }}
      >
        <span style={{ fontSize: 14 }}> Your brand is ranked</span> {ranking}
      </Typography>
      <Divider
        sx={{
          height: "1px",
          borderColor: "border.quaternary",
          borderBottomWidth: "1px",
          margin: "10px 0px",
        }}
      />
    </Box>
  );
};
export default RankingTypography;
