import { Card, Typography, useTheme } from "@mui/material";

const BlogWriterCard = ({
  title,
  children,
  width,
  centerContent,
  required,
}) => {
  const theme = useTheme();
  return (
    <Card
      style={{
        padding: "0px 85px 70px 85px",
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        width: width,
        paddingTop: centerContent ? 200 : 20,
        backgroundColor: theme.palette.background.secondary,
      }}
    >
      <Typography
        style={{ fontSize: 40, fontWeight: 500 }}
        color="text.primary"
      >
        {title} {required && <span style={{ color: "#718CF9" }}>*</span>}
      </Typography>
      {children}
    </Card>
  );
};
export default BlogWriterCard;
