export const rankingsLables = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
];

export const dummyLineData = [
  {
    xAxis: "Feb",
    NoGood: 3000,
    Competitor1: 1398,
    Competitor2: 1900,
    Competitor3: 4000,
  },
  {
    xAxis: "Mar",
    NoGood: 2000,
    Competitor1: 9800,
    Competitor2: 1800,
    Competitor3: 3000,
  },
  {
    xAxis: "Apr",
    NoGood: 2780,
    Competitor1: 3908,
    Competitor2: 1800,
    Competitor3: 4000,
  },
  {
    xAxis: "May",
    NoGood: 1890,
    Competitor1: 4800,
    Competitor2: 2000,
    Competitor3: 2500,
  },
  {
    xAxis: "Aug",
    NoGood: 2390,
    Competitor1: 3800,
    Competitor2: 2400,
    Competitor3: 4000,
  },
  {
    xAxis: "Sep",
    NoGood: 3490,
    Competitor1: 4300,
    Competitor2: 2900,
    Competitor3: 3000,
  },
];

export const dummyRadarData = [
  {
    benchmark: "label Recognition",
    NoGood: 120,
    Competitor1: 110,
    Competitor2: 150,
  },
  {
    benchmark: "Market Share",
    NoGood: 98,
    Competitor1: 130,
    Competitor2: 150,
  },
  {
    benchmark: "Product Quality",
    NoGood: 86,
    Competitor1: 130,
    Competitor2: 150,
  },
  {
    benchmark: "Innovation",
    NoGood: 99,
    Competitor1: 100,
    Competitor2: 150,
  },
  {
    benchmark: "Sustainability",
    NoGood: 85,
    Competitor1: 90,
    Competitor2: 150,
  },
  {
    benchmark: "Endorsements",
    NoGood: 65,
    Competitor1: 85,
    Competitor2: 150,
  },
];

export const dummyStackedData = [
  {
    label: "Competitor1",
    positive: 3400,
    neutral: 3000,
    negative: 2400,
  },
  {
    label: "Competitor2",
    positive: 4000,
    neutral: 2400,
    negative: 2400,
  },
  {
    label: "NoGood",
    positive: 4000,
    neutral: 2400,
    negative: 2400,
  },
  {
    label: "Competitor3",
    positive: 4000,
    neutral: 2400,
    negative: 2400,
  },
];
export const dummyLLMRankingData = [
  { label: "ChatGpt", score: 90 },
  { label: "Gemini", score: 60 },
  { label: "Perplexity", score: 30 },
];

export const dummyRegionsData = [
  {
    label: "United state",
    scores: {
      ChatGpt: 70,
      Perplexity: 50,
      Gemini: 40,
    },
  },
  {
    label: "United state",
    scores: {
      ChatGpt: 70,
      Perplexity: 50,
      Gemini: 40,
    },
  },
];

export const dummyCompetitorsData = [
  { label: "ChatGpt", score: 90 },
  { label: "Gemini", score: 60 },
  { label: "Perplexity", score: 30 },
  { label: "ChatGpt", score: 90 },
  { label: "Gemini", score: 60 },
  { label: "Perplexity", score: 30 },
];
