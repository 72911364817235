import React from "react";
import { useTheme } from "@mui/material";

const StepperCompletedIcon = ({ color, pathcolor }) => {
  const theme = useTheme();
  const squareColor = color || theme.palette.text.brand;
  const pathColor = pathcolor || theme.palette.text.primary;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.9951 -0.00506544H0.995132C0.729916 -0.00506544 0.475561 0.100292 0.288025 0.287828C0.100489 0.475364 -0.00486803 0.729718 -0.00486803 0.994935V18.9949C-0.00486803 19.2602 0.100489 19.5145 0.288025 19.702C0.475561 19.8896 0.729916 19.9949 0.995132 19.9949H18.9951C19.2604 19.9949 19.5147 19.8896 19.7022 19.702C19.8898 19.5145 19.9951 19.2602 19.9951 18.9949V0.994935C19.9951 0.729718 19.8898 0.475364 19.7022 0.287828C19.5147 0.100292 19.2604 -0.00506544 18.9951 -0.00506544Z"
        fill={squareColor}
      />
      <path
        d="M8.53463 13.508C8.41277 13.4572 8.30217 13.3828 8.20921 13.2891L6.28921 11.3691C6.10091 11.1808 5.99512 10.9254 5.99512 10.6591C5.99512 10.3928 6.10091 10.1374 6.28921 9.94909C6.47751 9.76078 6.73291 9.65499 6.99921 9.65499C7.26551 9.65499 7.52091 9.76078 7.70921 9.94909L8.91921 11.1691L12.2892 7.78909C12.4775 7.60078 12.7329 7.495 12.9992 7.495C13.2655 7.495 13.5209 7.60078 13.7092 7.78909C13.8975 7.97739 14.0033 8.23278 14.0033 8.49909C14.0033 8.76539 13.8975 9.02078 13.7092 9.20909L9.62921 13.2891C9.53625 13.3828 9.42565 13.4572 9.30379 13.508C9.18193 13.5587 9.05122 13.5849 8.91921 13.5849C8.7872 13.5849 8.65649 13.5587 8.53463 13.508Z"
        fill={pathColor}
      />
    </svg>
  );
};
export default StepperCompletedIcon;
