import * as React from "react";
import Button from "@mui/material/Button";
import useForkRef from "@mui/utils/useForkRef";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useTheme } from "@mui/material";

const isDateDisabled = (date, startDate, endDate) => {
  return date.isBefore(startDate, "day") || date.isAfter(endDate, "day");
};

// const frequencies = ["Daily", "Monthly", "Yearly"];

const DateRangeButtonField = React.forwardRef((props, ref) => {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref: containerRef } = {},
    inputProps: { "aria-label": ariaLabel } = {},
    backgroundColor,
  } = props;

  const handleRef = useForkRef(ref, containerRef);

  return (
    <Button
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={handleRef}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      style={{
        color: "#8C8C8C",
        backgroundColor: backgroundColor,
        borderRadius: "10px 0 0 10px",
        border: "none",
        padding: "4px 8px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <CalendarMonthOutlinedIcon style={{ fontSize: 15, marginRight: 5 }} />
      {label ? label : "Pick a date range"}
    </Button>
  );
});

DateRangeButtonField.fieldType = "single-input";
DateRangeButtonField.backgroundColor = "single-input";

const ButtonDateRangePicker = React.forwardRef((props, ref) => {
  const { startDate, endDate, ...otherProps } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  return (
    <DateRangePicker
      slots={{ field: DateRangeButtonField, ...otherProps.slots }}
      slotProps={{
        field: { setOpen },
        popper: {
          sx: {
            "& .MuiPaper-root": {
              backgroundColor: theme.palette.background.secondary,
              borderRadius: "10px",
              boxShadow: theme.shadows[3],
              padding: "8px",
            },
            "& .MuiPickersDay-root": {
              color: theme.palette.text.secondary,
              "&:hover": {
                backgroundColor: theme.palette.dropdown.hover,
              },
              "&.Mui-selected": {
                backgroundColor: theme.palette.text.brand,
                color: theme.palette.text.chiptext,
              },
            },
            "& .MuiTypography-root": {
              color: theme.palette.text.secondary,
            },
            "& .MuiCalendarPicker-root": {
              backgroundColor: theme.palette.background.secondary,
            },
            "& .MuiPickersArrowSwitcher-root .MuiButtonBase-root": {
              color: theme.palette.text.secondary,
            },
          },
        },
      }}
      ref={ref}
      {...otherProps}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      shouldDisableDate={(date) => isDateDisabled(date, startDate, endDate)}
    />
  );
});

const DateRangePickerWithDropdown = ({
  backgroundColorValue,
  startDate,
  endDate,
  setValue,
  value,
}) => {
  const theme = useTheme();

  const BgColor = theme.palette.background[backgroundColorValue];

  // const [frequency, setFrequency] = React.useState("Daily");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: BgColor,
          borderRadius: 10,
          overflow: "hidden",
        }}
      >
        <ButtonDateRangePicker
          label={
            value[0] === null && value[1] === null
              ? null
              : value
                  .map((date) => (date ? date.format("MM/DD/YYYY") : "null"))
                  .join(" - ")
          }
          value={value}
          onChange={(newValue) => setValue(newValue)}
          startDate={startDate}
          endDate={endDate}
        />

        {/* Vertical Separator */}
        {/* <div
          style={{
            width: "1px",
            backgroundColor: "#D0D0D0",
            height: "30px",
          }}
        ></div> */}

        {/* <FormControl
          variant="outlined"
          style={{ marginLeft: 0, borderRadius: 0 }}
        >
          <Select
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            IconComponent={ExpandMoreOutlinedIcon}
            variant="standard"
            disableUnderline
            sx={{
              "& .MuiSelect-select": {
                padding: "4px 8px",
                fontSize: "14px",
                color: "#0D0D0D",
                fontWeight: 400,
              },
              "& .MuiSvgIcon-root": {
                fontSize: 15,
                color: "#0D0D0D",
                marginRight: "5px",
              },
            }}
          >
            {frequencies.map((frequency) => (
              <MenuItem
                key={frequency}
                value={frequency}
                sx={{
                  fontSize: "14px",
                  color: "#000000",
                }}
              >
                {frequency}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </div>
    </LocalizationProvider>
  );
};

export default DateRangePickerWithDropdown;
