import React from "react";
import { ListItem, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import CustomTooltip from "../CustomTooltip";

const SidebarButton = ({
  to,
  primary,
  IconComponent,
  isCollapsed,
  isDisabled,
  handleClick,
  collapsedPadding,
  expandedPadding,
  borderRadius = "8px",
  disableHover = false,
}) => {
  const location = useLocation();
  const theme = useTheme();

  const isActive = location.pathname === to;
  const color = isActive
    ? theme.palette.text.primary
    : theme.palette.text.secondary;
  const width = isCollapsed ? "20px" : "18px";
  const height = isCollapsed ? "20px" : "18px";
  const activeBackground = theme.palette.background.activeHover;
  const secondaryhoverBackground = theme.palette.background.secondaryhover;

  return (
    <CustomTooltip
      title={primary}
      arrow
      placement="right"
      disableHoverListener={!isCollapsed}
    >
      <ListItem
        button
        component={to ? Link : "div"}
        to={to}
        sx={{
          background: isActive ? activeBackground : "transparent",
          color,
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          p: isCollapsed ? collapsedPadding : expandedPadding,
          margin: isCollapsed ? "0px 0px 27px 0px" : "0px 0px 5px 0px",
          borderRadius: borderRadius,
          justifyContent: "center",
          "&:hover": disableHover
            ? { backgroundColor: "transparent", boxShadow: "none" }
            : {
                backgroundColor: secondaryhoverBackground,
                boxShadow: "none",
              },
          "&:focus": {
            background: isActive ? activeBackground : "transparent",

            boxShadow: "none",
          },
          "&:active": {
            background: isActive ? activeBackground : "transparent",

            boxShadow: "none",
          },
          "& .css-tlelie-MuiListItemText-root": {
            margin: 0,
          },
        }}
        onClick={handleClick}
        disabled={isDisabled}
      >
        <ListItemIcon
          sx={{
            color,
            minWidth: "18px",
          }}
        >
          <IconComponent color={color} sx={{ width, height }} />
        </ListItemIcon>
        {!isCollapsed && (
          <ListItemText
            primary={primary}
            primaryTypographyProps={{
              sx: { fontSize: "14px", fontWeight: 500, lineHeight: "18px" },
            }}
          />
        )}
      </ListItem>
    </CustomTooltip>
  );
};

export default SidebarButton;
