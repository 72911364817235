import {
  Box,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../../api";
import AddMoreButton from "../../../../CommonComponents/FormField/components/AddMoreButton/AddMoreButton";
import SearchInput from "../../../../CommonComponents/SearchInput";
import WorkspacePageTemplate from "../PageTemplate/PageTemplate";
import AllPeopleList from "./AllPeopleList/AllPeopleList";
import RolesDropdown from "./RolesDropdown/RolesDropDown";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "12px !important",
    padding: "20px 8px 28px 18px",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    boxShadow: "none !important",
    rowGap: 10,
    width: "100%",
    marginTop: 30,
  },

  subtitle: {
    fontSize: 14,
    color: "#000000",
    fontWeight: 500,
  },
}));
const People = () => {
  const { brandId } = useParams();
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState();
  const [email, setEmail] = useState();
  const [peopleList, setPeopleList] = useState([]);
  const [invitedPeopleList, setInvitedPeopleList] = useState([]);
  const [inActiveUsersEmails, setinActiveUsersEmails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("author");
  const [loadingPeople, setLoadingPeople] = useState(false);
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const filteredData = useMemo(() => {
    return searchQuery
      ? peopleList.filter(
          (item) =>
            item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.last_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : peopleList;
  }, [peopleList, searchQuery]);

  const handleAddPerson = () => {
    setLoading(true);
    const values = {
      emails: [email],
      isAdmin: isAdmin,
      invited_by: loggedInUser.id,
    };
    axios
      .post(`${API_URL}/brands/${brandId}/users`, values)
      .then((response) => {
        setPeopleList(response.data.users);
        setInvitedPeopleList(response.data.invitations);
        setinActiveUsersEmails(response.data.inactive_user_emails);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const refetch = () => {
    setLoadingPeople(true);
    axios
      .get(`${API_URL}/brands/${brandId}`)
      .then((response) => {
        setLoadingPeople(false);
        setInvitedPeopleList(response.data.invitations);
        setinActiveUsersEmails(response.data.inactive_user_emails);
        setPeopleList(response.data.users);
      })
      .catch((err) => {
        setLoadingPeople(false);
      });
  };
  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [brandId]);

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setDropdownValue(value);

    if (value === "admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };
  const theme = useTheme();
  return (
    <WorkspacePageTemplate
      title="People"
      navigateTo={`/workspace-settings/${brandId}`}
    >
      <Divider sx={{ width: "100%", borderColor: "border.tertiary" }} />
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 450,
          lineHeight: "20px",
          marginTop: 10,
        }}
        color="text.tertiary"
      >
        Invite People
      </Typography>
      <Card
        className={classes.card}
        sx={{ backgroundColor: "background.secondary" }}
      >
        <Typography
          variant="h3"
          sx={{ fontSize: 14, fontWeight: 500, lineHeight: "20px" }}
          color="text.primary"
        >
          Invite people to your workspace by email
        </Typography>

        <TextField
          sx={{
            width: "50%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: `1px solid ${theme.palette.border.secondary}`,
                borderRadius: "11px",
              },
              "&:hover fieldset": {
                border: `1px solid ${theme.palette.border.secondary}`,
              },
              "&.Mui-focused fieldset": {
                border: `1px solid ${theme.palette.border.secondary}`,
              },
            },
            "& .MuiInputBase-input::placeholder": {
              color: theme.palette.text.quaternary,
            },
          }}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="name@goodie.com"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FormControl>
                  <RolesDropdown
                    dropdownValue={dropdownValue}
                    handleDropdownChange={handleDropdownChange}
                  />
                </FormControl>
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <AddMoreButton
            showIcon={false}
            title={
              loading ? (
                <CircularProgress
                  style={{
                    width: 20,
                    height: 20,
                    color: "#2D333A",
                  }}
                />
              ) : (
                "Send Invites"
              )
            }
            onClick={() => handleAddPerson()}
          />
        </div>
      </Card>
      <Divider
        sx={{ width: "100%", marginTop: 10, borderColor: "border.tertiary" }}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
          marginBottom: 60,
        }}
      >
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 450,
            lineHeight: "20px",
          }}
          color="text.tertiary"
        >
          All People
        </Typography>
        <SearchInput
          placeholder="Search people..."
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </Box>
      {loadingPeople ? (
        <div
          style={{
            position: "absolute",
            top: "95%",
            left: "60%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              color: "#2D333A",
            }}
          />
        </div>
      ) : (
        <AllPeopleList
          peopleList={filteredData}
          refetch={refetch}
          invitedUsers={invitedPeopleList}
          inActiveUserEmails={inActiveUsersEmails}
        />
      )}
    </WorkspacePageTemplate>
  );
};
export default People;
