import { Grid, useTheme } from "@mui/material";
import ChartCard from "../../components/ChartCard/ChartCard";
import PageLayout from "../../components/PageLayout/PageLayout";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import LLMRankingTable from "../../components/LLMRankingTable/LLMRankingTable";
import RankingProgressCard from "../../components/RankingProgressCard/RankingProgressCard";
import RankingWheelsCard from "../../components/RankingWheelsCard/RankingWheelsCard";
import LineChartCard from "../../components/LineChartCard/LineChartCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import GlobalIcon from "../../../../CommonComponents/customIcons/GlobalIcon";

const PerformanceAnalysis = () => {
  const theme = useTheme();

  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="Performance Analysis"
        description="Gain insights into brand ranking across models, regions, and languages. Track ranking shifts over time, focusing on high-intent prompts, fact-checking, and hallucination monitoring."
        hasGlobalFilters={false}
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={6.8}>
            <RankingProgressCard
              icon={
                <LayersOutlinedIcon
                  style={{ width: 15, height: 15 }}
                  color={theme.palette.text.primary}
                />
              }
              title="Average Performance Score"
              tooltipPrimary="Shows your brand’s performance score across various LLM models and competitors. Performance score is calculated by making high intent searches on LLMs and evaluating their responses."
              tooltipSecondary="All scores are out of 100."
              height={266}
              hasTypographyRanking="1st"
              gridTitle2="Company"
              hasLLMSelect
              isPerformance
            />
          </Grid>
          <Grid item xs={5}>
            <RankingWheelsCard
              icon={<VisibilityIcon color={theme.palette.text.primary} />}
              title="Ranking Across LLM Models"
              tooltipPrimary="Shows brand performance score by individual LLM models. Higher scores indicate greater brand prominence within that model."
              tooltipSecondary="All scores are out of 100."
              height={266}
              wheelsGrid={4}
              isPerformance
            />
          </Grid>{" "}
        </Grid>

        <LineChartCard
          icon={<VisibilityIcon color={theme.palette.text.primary} />}
          title="Brand Ranking Over Time"
          tooltipPrimary="Track your brand’s performance score trend over time across selected competitors and LLMs. Use the dropdown to select your preferred LLM. "
          hasCaledarSelect
          hasLLMselect
          height={328}
          chartHasXasix
          // chartHasYaxis
          chartHeight={200}
          chartHasTooltip
          chartHasMarginTop={20}
          chartHasMarginLeft={-20}
          chartHasGrid
        />

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={5.85}>
            <ChartCard
              icon={<GlobalIcon color={theme.palette.text.primary} />}
              title="Ranking by Geography"
              tooltipMessagePrimary="Display your brand’s visibility rank across the selected region(s)."
              tooltipMessageSecondary="Rank 1 indicates the highest visibility."
              height={254}
            >
              <LLMRankingTable rankingBy="Region" />
            </ChartCard>
          </Grid>
          <Grid item xs={5.85}>
            {/* <ChartCard
              icon={<SentimentIcon color={theme.palette.text.primary} />}
              title="Ranking by Language"
              tooltipMessagePrimary="Shows your brand’s visibility rank across languages and LLMs. "
              height={254}
            >
              <LLMRankingTable />
            </ChartCard> */}
          </Grid>{" "}
        </Grid>
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default PerformanceAnalysis;
