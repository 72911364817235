import { Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import FormikTextField from "../../../../../../CommonComponents/FormField/components/TextField/TextField";

const NamesSection = () => {
  const { values } = useFormikContext();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ fontSize: 14, fontWeight: 450 }}
          color="text.secondary"
        >
          You’re creating a Goodie account using
        </Typography>
        <Typography
          style={{ fontSize: 14, fontWeight: 450 }}
          color="text.secondary"
        >
          {values.email}
        </Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
            <Typography style={{ fontSize: 15 }}>First Name</Typography>
            <FormikTextField
              name="first_name"
              placeholder="First name"
              margin={false}
            />
          </div>
        </Grid>{" "}
        <Grid item xs={12}>
          <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
            <Typography style={{ fontSize: 15 }}>Last Name</Typography>
            <FormikTextField
              name="last_name"
              placeholder="Last name"
              margin={false}
            />
          </div>
        </Grid>
      </Grid>{" "}
    </>
  );
};
export default NamesSection;
