import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTheme } from "@mui/material";

const PercentageWheel = ({ percentage, width = 64, height = 64 }) => {
  const theme = useTheme();

  const getPerformanceColor = (percentage) => {
    if (percentage >= 80) {
      return theme.palette.circle.positive;
    } else if (percentage >= 50) {
      return theme.palette.circle.neutral;
    } else {
      return theme.palette.circle.negative;
    }
  };

  return (
    <div style={{ width: width, height: height }}>
      <CircularProgressbar
        value={Math.round(percentage)}
        text={`${Math.round(percentage)}%`}
        strokeWidth={9}
        styles={buildStyles({
          strokeLinecap: "round",
          textSize: "20px",
          trailColor: theme.palette.background.tertiary,
          textColor: theme.palette.text.primary,
          pathColor: getPerformanceColor(percentage),
        })}
      />
    </div>
  );
};

export default PercentageWheel;
