import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Avatar,
  Grid,
  IconButton,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import Doc from "../../../../CommonComponents/customIcons/doc";
import { API_URL } from "../../../../api";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import Tag from "./components/Tag/Tag";
import CustomSearchInput from "./components/CustomSearch/CustomSearchInput";
import RecentsCard from "../RecentsCard/RecentsCard";
import { useBlog } from "../../../../contexts/blogContext";

const RecentSection = () => {
  const { brandId } = useParams();
  const [data, setData] = useState([]);
  const [visibleRows, setVisibleRows] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { setEditedBlog: setEditedBlogContext } = useBlog();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/brands/${brandId}/recents`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching recent data:", error);
        setError("Failed to fetch recent data");
        setLoading(false);
      });
  }, [brandId]);

  const handleLoadMore = () => {
    setVisibleRows((prev) => Math.min(prev + 5, data.length));
  };

  const filteredData = useMemo(() => {
    return data.filter(
      (row) =>
        row.title?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        row.user?.first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        row.user?.last_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [data, searchQuery]);

  return (
    <Box
      sx={{
        margin: "40px auto",
        pl: "0px",
        pr: "0px",
        borderTop: `1px solid ${theme.palette.border.tertiary}`,
      }}
    >
      <Typography
        variant="h4"
        style={{
          fontWeight: 500,
          fontSize: 14,
          marginBottom: 40,
          marginTop: 20,
        }}
        color="text.tertiary"
      >
        Recent AI Content
      </Typography>
      <Grid container spacing={2} sx={{ mb: 5 }}>
        {data.slice(0, 4).map((row) => (
          <Grid item xs={6}>
            <RecentsCard row={row} />{" "}
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        alignItems="flex-start"
        sx={{
          padding: "0px",
          borderTop: `1px solid ${theme.palette.border.tertiary}`,
          marginBottom: "52px",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            paddingTop: "20px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "18px",
              textAlign: "left",
            }}
            color="text.tertiary"
          >
            All Files
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            paddingTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomSearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Grid>
      </Grid>

      <Box sx={{ marginBottom: "22px" }}>
        <Grid container>
          <Grid item xs={5}>
            <Typography
              sx={{
                fontSize: "14.805px",
                fontWeight: 500,
                lineHeight: "18.506px",
                textAlign: "left",
                paddingLeft: "16px",
              }}
              color="text.primary"
            >
              Title
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography
              sx={{
                fontSize: "14.805px",
                fontWeight: 500,
                lineHeight: "18.506px",
              }}
              color="text.primary"
            >
              Type
            </Typography>
          </Grid>
          <Grid item xs={2.9}>
            <Typography
              sx={{
                fontSize: "14.805px",
                fontWeight: 500,
                lineHeight: "18.506px",
                textAlign: "left",
              }}
              color="text.primary"
            >
              Last Edited
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                fontSize: "14.805px",
                fontWeight: 500,
                lineHeight: "18.506px",
                textAlign: "left",
              }}
              color="text.primary"
            >
              Author
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "90%",
            left: "60%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              color: "#2D333A",
            }}
          />
        </div>
      ) : (
        <>
          {filteredData.slice(0, visibleRows).map((row) => (
            <Box
              key={row.id}
              sx={{
                padding: "11.1px 20px 11.1px 20px",
                backgroundColor: "background.secondary",
                borderRadius: "10px",
                boxShadow: "0px 6px 16px 0px rgba(0, 0, 0, 0.02)",
                marginBottom: "10px",
                cursor: "pointer",
                border: `1px solid ${theme.palette.background.secondary}`,
                "&:hover": {
                  backgroundColor: "background.secondaryhover",
                  border: `1px solid ${theme.palette.border.tertiary}`,
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setEditedBlogContext(row.content);
                navigate(`/dashboard/${brandId}/blog/${row.title}`);
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={5}>
                  <Typography
                    noWrap
                    sx={{
                      maxWidth: "345px",
                      textAlign: "left",
                      fontSize: "14.805px",
                      fontWeight: 500,
                      lineHeight: "18.506px",
                    }}
                    color="text.primary"
                  >
                    {row.title}
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Tag>
                    <Doc color={theme.palette.text.primary}></Doc> {row.type}
                  </Tag>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      fontSize: "12.954px",
                      fontWeight: 450,
                      lineHeight: "15.315px",
                      letterSpacing: "-0.13px",
                      textAlign: "left",
                    }}
                    color="text.tertiary"
                  >
                    {format(
                      new Date(row.updated_at),
                      "MMMM dd, yyyy '@' h:mma"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14.805px",
                      fontWeight: 400,
                      lineHeight: "18.506px",
                      textAlign: "left",
                    }}
                    color="text.primary"
                  >
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        marginRight: "10px",
                        backgroundColor: "background.tertiary",
                        color: "text.secondary",
                        textAlign: "center",
                        fontSize: "11.537px",
                        fontWeight: 400,
                      }}
                    >
                      {row.user.first_name
                        ? row.user.first_name.charAt(0)
                        : row.user.email.charAt(0)}
                    </Avatar>
                    <Typography noWrap sx={{ maxWidth: "85px" }}>
                      {row.user.first_name && row.user.last_name
                        ? `${row.user.first_name} ${row.user.last_name.charAt(
                            0
                          )}.`
                        : row.user.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={0.5} sx={{ textAlign: "right" }}>
                  <IconButton
                    sx={{
                      color: "text.tertiary",
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}

          {visibleRows < filteredData.length && (
            <Button
              onClick={handleLoadMore}
              sx={{
                display: "block",
                margin: "10px 0 0 0",
                color: "text.tertiary",
                padding: "10px 12px",
                fontSize: "14px",
                fontWeight: 450,
                lineHeight: "20px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              Load More
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default RecentSection;
