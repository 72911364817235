import DNAStep from "../Step/Step";
import PersonaForm from "./Form/PersonaForm";

const Persona = ({ brandDetails }) => {
  return (
    <DNAStep
      title="Persona"
      subtitle="Identify your target audience by selecting relevant demographics, interests, and job roles. This information helps Goodie tailor the content to speak directly to your audience's needs, ensuring a more precise and effective communication strategy for your brand."
      required
      children={<PersonaForm brandDetails={brandDetails} />}
    />
  );
};
export default Persona;
