import React from "react";
import { ListItemText, IconButton, useTheme } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";

const WorkspaceNameDropdown = ({
  workspaceName,
  anchorEl,
  setAnchorEl,
  inTooltip,
}) => {
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const theme = useTheme();
  return (
    <>
      <ListItemText
        primary={workspaceName}
        sx={{ color: "textprimary" }}
        primaryTypographyProps={{
          sx: {
            fontSize: inTooltip ? "14px" : "22px",
            fontWeight: inTooltip ? 500 : 450,
            cursor: "pointer",
            lineHeight: inTooltip ? "18px" : "normal",
          },
        }}
        onClick={handleClick}
      />
      <IconButton onClick={handleClick} sx={{ color: "text.tertiary", p: 0 }}>
        {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
      </IconButton>
    </>
  );
};

export default WorkspaceNameDropdown;
