import { CircularProgress, Grid, useTheme, Typography } from "@mui/material";
import WorkSpaceCard from "./components/Card/Card";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import WorkspacePageTemplate from "./components/PageTemplate/PageTemplate";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import PeopleIcon from "../../CommonComponents/customIcons/PeopleIcon";
import KnowledgeBaseIcon from "../../CommonComponents/customIcons/KnowledgeBaseIcon";
import DNA from "../../CommonComponents/customIcons/DNA";
import axios from "axios";
import { API_URL } from "../../api";

const Workspace = () => {
  const brandOverview = JSON.parse(localStorage.getItem("workspace"));
  const brandName = brandOverview?.name;
  const { brandId } = useParams();
  const userId = JSON.parse(localStorage.getItem("loggedInUser")).id;
  const [is_admin, setIsAdmin] = useState();
  const [loading, setLoading] = useState();
  const theme = useTheme();

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${API_URL}/brands/check-admin?user_id=${userId}&brand_id=${brandId}`
      )
      .then((response) => {
        setLoading(false);
        setIsAdmin(response.data.is_admin);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [userId, brandId]);

  const cards = useMemo(
    () => [
      {
        icon: <SettingsOutlinedIcon color={theme.palette.text.primary} />,
        title: "General",
        description:
          "Manage your company data, connected services, and data sharing settings",
        isIncomplete: "",
        navigateTo: "general-settings",
      },
      {
        icon: (
          <DNA color={theme.palette.text.primary} width="24px" height="24px" />
        ),
        title: "Brand DNA",
        description:
          "Provide details about your brand to ensure personalized results",
        isIncomplete: !brandOverview.is_brand_dna_completed,
        navigateTo: "brand-dna-overview",
      },
      {
        icon: <LinkOutlinedIcon color={theme.palette.text.primary} />,
        title: "Link Social Media",
        description: "Connect your company to your social media accounts",
        isIncomplete: false,
        navigateTo: "connect-accounts",
      },
      {
        icon: <PeopleIcon color={theme.palette.text.primary} />,
        title: "People",
        description: "Invite, remove and manage your company’s team members",
        isIncomplete: "",
        navigateTo: "people",
        requiresAdminAccess: true,
        adminAccess: is_admin,
      },
      {
        icon: <KnowledgeBaseIcon color={theme.palette.text.primary} />,
        title: "Knowledge Base",
        description:
          "Add writing samples for your workspace to reference when creating blogs",
        isIncomplete: "",
        navigateTo: "knowledge-base",
      },
    ],
    [brandOverview, is_admin, theme]
  );

  return (
    <WorkspacePageTemplate title="Workspace Settings">
      <Typography
        sx={{
          borderTop: `1px solid ${theme.palette.border.tertiary}`,
          pt: "20px",
          pb: "60px",
          fontSize: "14px",
          fontWeight: 450,
          lineHeight: "20px",
        }}
        color="text.tertiary"
      >
        All Settings
      </Typography>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "60%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              color: "#2D333A",
            }}
          />
        </div>
      ) : (
        <Grid container spacing={3} direction="row" alignItems="center">
          {cards.map((card, index) => {
            return (
              <Grid item xs={8} sm={4} md={4} key={index}>
                <WorkSpaceCard card={card} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </WorkspacePageTemplate>
  );
};
export default Workspace;
