import React from "react";

const Home = ({ color = "#AFB1B7" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1784vd4-MuiSvgIcon-root"
  >
    <path
      fill={color}
      d="M15.0001 5.99988L10.5001 2.05488C10.0876 1.68592 9.55352 1.48193 9.00007 1.48193C8.44662 1.48193 7.91259 1.68592 7.50007 2.05488L3.00007 5.99988C2.76185 6.21294 2.57175 6.4743 2.44243 6.76657C2.3131 7.05883 2.24753 7.37529 2.25007 7.69488V14.2499C2.25007 14.8466 2.48712 15.4189 2.90908 15.8409C3.33104 16.2628 3.90333 16.4999 4.50007 16.4999H13.5001C14.0968 16.4999 14.6691 16.2628 15.0911 15.8409C15.513 15.4189 15.7501 14.8466 15.7501 14.2499V7.68738C15.7515 7.36905 15.6855 7.05404 15.5562 6.76314C15.4269 6.47225 15.2373 6.2121 15.0001 5.99988ZM10.5001 14.9999H7.50007V11.2499C7.50007 11.051 7.57909 10.8602 7.71974 10.7196C7.86039 10.5789 8.05116 10.4999 8.25007 10.4999H9.75007C9.94898 10.4999 10.1397 10.5789 10.2804 10.7196C10.4211 10.8602 10.5001 11.051 10.5001 11.2499V14.9999ZM14.2501 14.2499C14.2501 14.4488 14.1711 14.6396 14.0304 14.7802C13.8897 14.9209 13.699 14.9999 13.5001 14.9999H12.0001V11.2499C12.0001 10.6531 11.763 10.0808 11.3411 9.65889C10.9191 9.23693 10.3468 8.99988 9.75007 8.99988H8.25007C7.65333 8.99988 7.08104 9.23693 6.65908 9.65889C6.23712 10.0808 6.00007 10.6531 6.00007 11.2499V14.9999H4.50007C4.30116 14.9999 4.11039 14.9209 3.96974 14.7802C3.82909 14.6396 3.75007 14.4488 3.75007 14.2499V7.68738C3.75021 7.58089 3.77302 7.47566 3.81698 7.37867C3.86095 7.28168 3.92507 7.19517 4.00507 7.12488L8.50507 3.18738C8.64194 3.06714 8.81789 3.00083 9.00007 3.00083C9.18225 3.00083 9.3582 3.06714 9.49507 3.18738L13.9951 7.12488C14.0751 7.19517 14.1392 7.28168 14.1832 7.37867C14.2271 7.47566 14.2499 7.58089 14.2501 7.68738V14.2499Z"
    />
  </svg>
);

export default Home;
