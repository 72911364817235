import { Grid, Typography } from "@mui/material";

const TitlesGrid = ({ title2, title3 = "Score", grid1 = 1.5, grid3 = 4 }) => {
  return (
    <Grid container>
      <Grid item xs={grid1}>
        <Typography
          style={{ fontWeeight: 400, fontSize: 12 }}
          color="text.tertiary"
        >
          #
        </Typography>
      </Grid>
      <Grid item xs={6.5}>
        <Typography
          style={{ fontWeeight: 400, fontSize: 12 }}
          color="text.tertiary"
        >
          {title2}
        </Typography>
      </Grid>
      <Grid
        item
        xs={grid3}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Typography
          style={{ fontWeeight: 400, fontSize: 12 }}
          color="text.tertiary"
        >
          {title3}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default TitlesGrid;
