export const interestsOptions = ["Technology", "Health", "Finance"];

export const jobTitlesOptions = [
  "Software Engineer",
  "Product Manager",
  "Data Scientist",
  "Marketing Manager",
  "Graphic Designer",
  "Sales Manager",
  "Project Manager",
  "Business Analyst",
  "UX/UI Designer",
  "Financial Analyst",
  "Human Resources Manager",
  "Customer Service Representative",
  "Operations Manager",
  "Software Developer",
  "Web Developer",
  "Content Writer",
  "Database Administrator",
  "Systems Analyst",
  "Network Engineer",
  "IT Support Specialist",
];

export const jobFunctionsOptions = [
  "Engineering",
  "Marketing",
  "Sales",
  "Product Management",
  "Human Resources",
  "Finance",
  "Operations",
  "Customer Service",
  "Design",
  "Data Analysis",
  "IT Support",
  "Business Development",
  "Project Management",
  "Research and Development",
  "Administration",
  "Legal",
  "Supply Chain Management",
  "Quality Assurance",
  "Content Creation",
  "Consulting",
  "Training and Development",
  "UX/UI Design",
];

export const ageRangeOptions = ["18–24", "25–34", "35–44", "45–54", "55+"];

export const genderOptions = [
  "Male",
  "Female",
  "All genders",
  "Prefer not to say",
];
