import React from "react";

const OverviewIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "18px"}
    height={height ? height : "18px"}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path
      d="M13.4 2.3335H2.6C2.44087 2.3335 2.28826 2.39671 2.17574 2.50923C2.06321 2.62175 2 2.77437 2 2.9335V13.7335C2 13.8926 2.06321 14.0452 2.17574 14.1578C2.28826 14.2703 2.44087 14.3335 2.6 14.3335H13.4C13.5591 14.3335 13.7117 14.2703 13.8243 14.1578C13.9368 14.0452 14 13.8926 14 13.7335V2.9335C14 2.77437 13.9368 2.62175 13.8243 2.50923C13.7117 2.39671 13.5591 2.3335 13.4 2.3335ZM7.4 13.1335H3.2V8.9335H7.4V13.1335ZM7.4 7.7335H3.2V3.5335H7.4V7.7335ZM12.8 13.1335H8.6V8.9335H12.8V13.1335ZM12.8 7.7335H8.6V3.5335H12.8V7.7335Z"
      fill={color}
    />
  </svg>
);

export default OverviewIcon;
