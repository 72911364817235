import React from "react";

const Visibility = ({ color = "#AFB1B7", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "18px"}
    height={height ? height : "18px"}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path
      d="M14.6134 7.73268C13.2667 4.60602 10.7334 2.66602 8.00004 2.66602C5.26671 2.66602 2.73338 4.60602 1.38671 7.73268C1.35 7.81679 1.33105 7.90758 1.33105 7.99935C1.33105 8.09112 1.35 8.1819 1.38671 8.26602C2.73338 11.3927 5.26671 13.3327 8.00004 13.3327C10.7334 13.3327 13.2667 11.3927 14.6134 8.26602C14.6501 8.1819 14.669 8.09112 14.669 7.99935C14.669 7.90758 14.6501 7.81679 14.6134 7.73268ZM8.00004 11.9993C5.88671 11.9993 3.88671 10.4727 2.73338 7.99935C3.88671 5.52602 5.88671 3.99935 8.00004 3.99935C10.1134 3.99935 12.1134 5.52602 13.2667 7.99935C12.1134 10.4727 10.1134 11.9993 8.00004 11.9993ZM8.00004 5.33268C7.47263 5.33268 6.95706 5.48908 6.51852 5.7821C6.07999 6.07511 5.7382 6.49159 5.53637 6.97886C5.33453 7.46613 5.28172 8.00231 5.38462 8.51959C5.48751 9.03687 5.74149 9.51203 6.11443 9.88497C6.48737 10.2579 6.96252 10.5119 7.4798 10.6148C7.99709 10.7177 8.53326 10.6649 9.02053 10.463C9.5078 10.2612 9.92428 9.9194 10.2173 9.48087C10.5103 9.04234 10.6667 8.52677 10.6667 7.99935C10.6667 7.29211 10.3858 6.61383 9.88566 6.11373C9.38556 5.61363 8.70729 5.33268 8.00004 5.33268ZM8.00004 9.33268C7.73634 9.33268 7.47855 9.25448 7.25928 9.10798C7.04002 8.96147 6.86912 8.75323 6.7682 8.50959C6.66729 8.26596 6.64088 7.99787 6.69233 7.73923C6.74378 7.48059 6.87077 7.24301 7.05724 7.05654C7.24371 6.87007 7.48128 6.74308 7.73992 6.69164C7.99857 6.64019 8.26665 6.66659 8.51029 6.76751C8.75392 6.86843 8.96216 7.03932 9.10867 7.25859C9.25518 7.47785 9.33338 7.73564 9.33338 7.99935C9.33338 8.35297 9.1929 8.69211 8.94285 8.94216C8.6928 9.19221 8.35367 9.33268 8.00004 9.33268Z"
      fill={color}
    />
  </svg>
);

export default Visibility;
