import { Box } from "@mui/material";
import DNAStep from "../Step/Step";
import GlobalForm from "./components/GlobalForm/GlobalForm";

const Global = ({ brandDetails }) => {
  return (
    <DNAStep
      title="Global"
      subtitle="Select a primary and additional countries to enable Goodie's AI-powered platform to perform comprehensive global analysis and comparisons. This helps Goodie assess your Answer Engine Optimization (AEO) ranking on a worldwide scale, providing insights into how your content performs across different regions and highlighting opportunities to optimize for a global audience."
      required
      children={<GlobalForm />}
    />
  );
};
export default Global;
