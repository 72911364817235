import { Card, Typography, Box, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BoxWithStatus from "../../../../CommonComponents/BoxWithStatus";
import EditIcon from "../../../../CommonComponents/customIcons/EditIcon";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "12px !important",
    padding: "12px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    boxShadow: "none !important",
  },
  iconTypography: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    columnGap: 5,
  },
}));

const BrandDnaOverViewCard = ({ card }) => {
  const { title, isIncomplete } = card;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Card
      className={classes.card}
      sx={{
        backgroundColor: "background.secondary",
        border: `1px solid ${theme.palette.background.secondary}`,
        "&:hover": {
          backgroundColor: "background.secondaryhover",
          border: `1px solid ${theme.palette.border.tertiary}`,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <EditIcon sx={{ color: "text.tertiary" }} />
        <Typography
          sx={{ fontSize: 14, fontWeight: 500, ml: 1 }}
          color="text.primary"
        >
          {title}
        </Typography>
      </Box>

      <BoxWithStatus isIncomplete={isIncomplete} statusFirst></BoxWithStatus>
    </Card>
  );
};

export default BrandDnaOverViewCard;
