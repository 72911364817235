import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../../../api";
import ChartCard from "../../../../components/ChartCard/ChartCard";
import OverallLineChartCard from "../OveralLineChartCard/OverallLineChartCard";

const AverageLineCard = ({
  title,
  tooltipPrimary,
  tooltipSecondary,
  height = 240,
  index,
  icon,
  widget_type = "Sentiment",
  navigateTo,
}) => {
  const { brandId } = useParams();
  const [data, setData] = useState({});
  const [isIncrease, setIsIncrease] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    const values = { brandId, widget_type };
    axios
      .post(`${ML_AEO_URL}/aeo/sentiment-visibility-widget`, values)
      .then((response) => {
        setLoading(false);
        const data = response.data;
        setData(data);
        setIsIncrease(parseFloat(data.Movement) >= 0);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }, [brandId, widget_type]);

  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessagePrimary={tooltipPrimary}
      tooltipMessageSecondary={tooltipSecondary}
      height={height}
      isLoading={loading}
      isError={error}
      navigateTo={navigateTo || undefined}
    >
      <OverallLineChartCard data={data} isIncrease={isIncrease} index={index} />
    </ChartCard>
  );
};
export default AverageLineCard;
