import { TextField, useTheme } from "@mui/material";
import { Field } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorMessage from "../ErrorMessage";

const FormikTextField = ({
  name,
  placeholder,
  startAdornment,
  endAdornment,
  disabled,
  ispassword,
  margin = true,
  width,
  errorMessage,
}) => {
  const theme = useTheme();
  return (
    <>
      <Field
        name={name}
        as={TextField}
        variant="outlined"
        margin={margin ? "normal" : "none"}
        fullWidth
        placeholder={placeholder}
        disabled={disabled}
        type={ispassword ? "password" : "text"}
        sx={{
          width: width,

          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "1px solid",
              borderColor: `${theme.palette.border.secondary} !important`,
              borderRadius: "11px",
            },
            "&:hover fieldset": {
              border: "1px solid",
              borderColor: theme.palette.border.secondary,
            },
            "&.Mui-focused fieldset": {
              border: "1px solid",
              borderColor: theme.palette.border.secondary,
            },
          },
          ".MuiInputAdornment-root": {
            "& fieldset": {
              border: "1px solid",
              borderColor: theme.palette.border.secondary,
              borderRadius: "11px",
            },
            color: theme.palette.text.secondary,
          },
        }}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment
              position="start"
              style={{
                ".MuiInputAdornment-root": {
                  color: theme.palette.text.secondary,
                },
              }}
            >
              {startAdornment}
            </InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment
              position="end"
              sx={{
                pointerEvents: "auto",
                cursor: "pointer",
              }}
            >
              {endAdornment}
            </InputAdornment>
          ),
        }}
      />
      {errorMessage && <ErrorMessage name={name} />}
    </>
  );
};
export default FormikTextField;
