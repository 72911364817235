import React, { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Toolbar,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const headings = [
  { label: "Normal", value: "" },
  { label: "Heading 1", value: "1" },
  { label: "Heading 2", value: "2" },
  { label: "Heading 3", value: "3" },
  { label: "Heading 4", value: "4" },
];

const CustomToolbar = ({ quillRef }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedHeading, setSelectedHeading] = useState("Normal");

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHeadingSelect = (value, label) => {
    const quill = quillRef?.current?.getEditor();
    if (value === "") {
      quill?.format("header", false);
    } else {
      quill?.format("header", value);
    }
    setSelectedHeading(label);
    handleMenuClose();
  };

  const handleFormat = (format) => {
    const quill = quillRef?.current?.getEditor();
    const currentFormat = quill?.getFormat();

    const isActive = currentFormat[format];
    quill?.format(format, !isActive);
  };

  return (
    <Toolbar
      id="toolbar"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "8px",
        borderBottom: `1px solid ${theme.palette.border.tertiary}`,
      }}
    >
      <Box>
        <Typography
          onClick={handleMenuOpen}
          sx={{
            fontSize: 14,
            color: theme.palette.text.secondary,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          {selectedHeading}
          <ArrowDropDownIcon
            sx={{
              fontSize: 20,
              color: theme.palette.text.secondary,
              "&:hover": {
                color: theme.palette.text.secondary,
              },
            }}
          />
        </Typography>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: theme.palette.background.secondary,
              borderRadius: "8px",
              boxShadow: "none",
            },
            "& .MuiMenuItem-root": {
              color: theme.palette.text.secondary,
              "&:hover": {
                backgroundColor: theme.palette.dropdown.hover,
              },
              "&.Mui-selected": {
                backgroundColor: theme.palette.dropdown.selected,
              },
            },
          }}
        >
          {headings.map((heading) => (
            <MenuItem
              key={heading.value}
              onClick={() => handleHeadingSelect(heading.value, heading.label)}
              selected={selectedHeading === heading.label}
            >
              {heading.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, ml: 2 }}>
        <IconButton
          onClick={() => handleFormat("bold", true)}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatBoldIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("italic", true)}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatItalicIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("underline", true)}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatUnderlinedIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("strike", true)}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatStrikethroughIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, ml: 2 }}>
        <IconButton
          onClick={() => handleFormat("align", "left")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatAlignLeftIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("align", "center")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatAlignCenterIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("align", "right")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatAlignRightIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, ml: 2 }}>
        <IconButton
          onClick={() => handleFormat("list", "ordered")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatListNumberedIcon />
        </IconButton>
        <IconButton
          onClick={() => handleFormat("list", "bullet")}
          sx={{
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.text.secondary,
            },
          }}
        >
          <FormatListBulletedIcon />
        </IconButton>
      </Box>
    </Toolbar>
  );
};

export default CustomToolbar;
