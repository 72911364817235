import perplexityIcon from "../../../assets/images/perplexity.png";
import geminiIcon from "../../../assets/images/gemini.png";
import chatgptIcon from "../../../assets/images/chatgpt.png";

const LLMsMap = {
  "Google Gemini": { icon: geminiIcon, title: "Gemini" },
  OpenAI: { icon: chatgptIcon, title: "ChatGpt" },
  Perplexity: { icon: perplexityIcon, title: "Perplexity" },
};

export const getLLMsAvgPercentageScores = (
  evaluations,
  valueAttribute,
  llmName
) => {
  const firstDateKey = Object.keys(evaluations)[0];
  const evaluationsByDate = evaluations[firstDateKey];
  const firstBrandKey = Object.keys(evaluationsByDate)[0];
  const brandData = evaluationsByDate[firstBrandKey];
  const firstCountryKey = Object.keys(brandData)[0];
  const llmEvaluations = brandData[firstCountryKey];

  const scores = llmEvaluations.map((llm) => ({
    title: LLMsMap[llm.llm_name]?.title,
    value: llm[valueAttribute],
    icon: LLMsMap[llm.llm_name]?.icon,
  }));

  if (llmName) {
    const selectedLlm = scores.find(
      (score) => score.title === LLMsMap[llmName]?.title
    );
    return selectedLlm ? selectedLlm.value : [];
  }

  return scores;
};

export const transformDataByLLM = (data, attribute) => {
  const transformedData = [];

  // Loop through each date in the data object
  for (const date in data) {
    const dateObj = { xAxis: date };

    // Loop through each brand within the date
    for (const brand in data[date]) {
      // Loop through each country and get evaluations for each LLM
      for (const country in data[date][brand]) {
        const evaluations = data[date][brand][country];

        // Loop through each LLM evaluation
        evaluations.forEach((evaluation) => {
          const llmName = evaluation.llm_name;

          // Initialize the LLM entry in dateObj if not already done
          if (!dateObj[llmName]) {
            dateObj[llmName] = {};
          }

          // Set the specified attribute value for this brand under the LLM
          dateObj[llmName][brand] = evaluation[attribute];
        });
      }
    }

    // Push the result to the transformedData array
    transformedData.push(dateObj);
  }

  return transformedData;
};

export const extractDataForLLM = (transformedData, llmName) => {
  return transformedData.map((entry) => {
    const result = { xAxis: entry.xAxis };

    if (entry[llmName]) {
      Object.entries(entry[llmName]).forEach(([brand, value]) => {
        result[brand] = value;
      });
    }

    return result;
  });
};

export const getLLMsScores = (evaluations, valueAttribute, llmName) => {
  const firstDateKey = Object.keys(evaluations)[0];
  const evaluationsByDate = evaluations[firstDateKey];
  const firstBrandKey = Object.keys(evaluationsByDate)[0];
  const brandData = evaluationsByDate[firstBrandKey];
  const firstCountryKey = Object.keys(brandData)[0];
  const llmEvaluations = brandData[firstCountryKey];

  const scores = llmEvaluations.map((llm) => ({
    title: LLMsMap[llm.llm_name]?.title,
    value: llm[valueAttribute],
    icon: LLMsMap[llm.llm_name]?.icon,
  }));

  if (llmName) {
    const selectedLlm = scores.find(
      (score) => score.title === LLMsMap[llmName]?.title
    );
    return selectedLlm ? selectedLlm.value : [];
  }

  return scores;
};

export const getScores = (evaluations, valueAttribute, llmName) => {
  const firstDateKey = Object.keys(evaluations)[0];
  const evaluationsByDate = evaluations[firstDateKey];
  const firstBrandKey = Object.keys(evaluationsByDate)[0];
  const brandData = evaluationsByDate[firstBrandKey];
  const firstCountryKey = Object.keys(brandData)[0];
  const llmEvaluations = brandData[firstCountryKey];

  const scores = llmEvaluations.map((llm) => ({
    title: LLMsMap[llm.llm_name]?.title,
    value: llm[valueAttribute],
    icon: LLMsMap[llm.llm_name]?.icon,
  }));

  if (llmName) {
    const selectedLlm = scores.find(
      (score) => score.title === LLMsMap[llmName]?.title
    );
    return selectedLlm ? selectedLlm.value : [];
  }

  return scores;
};

export const getProgress = (evaluations, valueAttribute, llmName) => {
  const firstDateKey = Object.keys(evaluations)[0];
  const evaluationsByDate = evaluations[firstDateKey];
  const firstBrandKey = Object.keys(evaluationsByDate)[0];
  const brandData = evaluationsByDate[firstBrandKey];
  const firstCountryKey = Object.keys(brandData)[0];
  const llmEvaluations = brandData[firstCountryKey];

  const scores = llmEvaluations.map((llm) => ({
    title: LLMsMap[llm.llm_name]?.title,
    value: llm[valueAttribute],
    icon: LLMsMap[llm.llm_name]?.icon,
  }));

  if (llmName) {
    const selectedLlm = scores.find(
      (score) => score.title === LLMsMap[llmName]?.title
    );
    return selectedLlm ? selectedLlm.value : [];
  }

  return scores;
};

///////////////////////////////

export const transformLLMLineChartData = (originalData) => {
  // Transform the original data format
  const transformedData = originalData.map((item) => ({
    xAxis: item.xAxis,
    ...Object.fromEntries(
      Object.entries(item.data_points).map(([key, value]) => [
        key,
        Math.round(value * 100) / 100, // Round to 2 decimal places
      ])
    ),
  }));

  // Extract keys from the first transformed data object (excluding xAxis)
  const keys = Object.keys(transformedData[0]).filter((k) => k !== "xAxis");

  return { transformedData, keys }; // Return both the transformed data and keys
};

export const transformCompetitorsLineChartData = (originalData, key) => {
  const transformedData = originalData.map((item) => {
    const { xAxis, data_points } = item;

    const dataEntries = data_points[key];

    return {
      xAxis,
      ...Object.fromEntries(
        Object.entries(dataEntries).map(([entryKey, value]) => [
          entryKey,
          Math.round(value * 100) / 100,
        ])
      ),
    };
  });

  const keys = Object.keys(transformedData[0]).filter((k) => k !== "xAxis");

  return { transformedData, keys };
};

export const reorderKeys = (keys, targetKey) => {
  // Check if the target key exists in the keys array
  if (keys.includes(targetKey)) {
    // Filter out the target key and then concatenate it at the beginning
    return [targetKey, ...keys.filter((key) => key !== targetKey)];
  }

  // If the target key does not exist, return the original array
  return keys;
};

export const reorderTransformedData = (data, targetBrand) => {
  const targetItemIndex = data.findIndex((item) => item.label === targetBrand);
  if (targetItemIndex !== -1) {
    const targetItem = data[targetItemIndex];
    const otherItems = data.filter((_, index) => index !== targetItemIndex);

    return [targetItem, ...otherItems];
  }

  return data;
};
export const spreadSentimentData = (dataArray) => {
  return dataArray.map(({ label, sentiment }) => ({
    label,
    positive: Math.round(sentiment.positive * 100) / 100,
    negative: Math.round(sentiment.negative * 100) / 100,
    neutral: Math.round(sentiment.neutral * 100) / 100,
  }));
};

export const spreadSingleSentimentData = (dataObject) => {
  const { label, sentiment } = dataObject;

  return {
    label,
    positive: Math.round(sentiment.positive * 100) / 100,
    negative: Math.round(sentiment.negative * 100) / 100,
    neutral: Math.round(sentiment.neutral * 100) / 100,
  };
};

export const transformSentimentOverTime = (dataArray, keyName) => {
  return dataArray.map(({ xAxis, data_points }) => {
    const { positive, negative, neutral } = data_points[keyName];

    return {
      xAxis,
      positive: Math.round(positive * 100) / 100,
      negative: Math.round(negative * 100) / 100,
      neutral: Math.round(neutral * 100) / 100,
    };
  });
};
