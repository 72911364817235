import DNAStep from "../Step/Step";
import BrandVoiceForm from "./Form/BrandVoiceForm";

const BrandVoice = ({ brandDetails }) => {
  return (
    <DNAStep
      title="Brand Voice"
      subtitle="Set the tone for your content by defining your brand’s voice. Your selections here will guide Goodie in maintaining a consistent tone and style, making your content more authentic and true to your brand’s personality across all platforms."
      required
      children={<BrandVoiceForm brandDetails={brandDetails} />}
    />
  );
};
export default BrandVoice;
