import { MenuItem, Select } from "@mui/material";

const RolesDropdown = ({ dropdownValue, handleDropdownChange }) => {
  return (
    <Select
      variant="standard"
      disableUnderline
      value={dropdownValue}
      onChange={handleDropdownChange}
      sx={{
        padding: "0",
        "& .MuiSelect-select": {
          padding: "3px 4px 3px 8px",
          border: "none",
          backgroundColor: "background.tertiary",
          fontSize: "14px",
          color: "text.secondary",
          borderRadius: "5px",
        },
        "& .MuiSelect-icon": {
          color: "text.secondary",
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            backgroundColor: "background.secondary",
            "& .MuiMenuItem-root": {
              color: "text.primary",
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "dropdown.hover",
            },
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: "dropdown.selected",
            },
          },
        },
      }}
    >
      <MenuItem
        value="admin"
        sx={{ fontSize: "14px", color: "text.secondary" }}
      >
        Admin
      </MenuItem>
      <MenuItem
        value="author"
        sx={{ fontSize: "14px", color: "text.secondary" }}
      >
        Author
      </MenuItem>
    </Select>
  );
};
export default RolesDropdown;
