import React from "react";

const ComparisonIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "18px"}
    height={height ? height : "18px"}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path
      d="M1.66667 7.0394L7.66667 10.5061C7.76802 10.5646 7.88298 10.5954 8.00001 10.5954C8.11703 10.5954 8.23199 10.5646 8.33334 10.5061L14.3333 7.0394C14.4344 6.98079 14.5182 6.89668 14.5765 6.79547C14.6348 6.69427 14.6655 6.57952 14.6655 6.46273C14.6655 6.34593 14.6348 6.23119 14.5765 6.12999C14.5182 6.02878 14.4344 5.94466 14.3333 5.88606L8.33334 2.41939C8.23199 2.36088 8.11703 2.33008 8.00001 2.33008C7.88298 2.33008 7.76802 2.36088 7.66667 2.41939L1.66667 5.88606C1.56565 5.94466 1.48179 6.02878 1.4235 6.12999C1.3652 6.23119 1.33452 6.34593 1.33452 6.46273C1.33452 6.57952 1.3652 6.69427 1.4235 6.79547C1.48179 6.89668 1.56565 6.98079 1.66667 7.0394ZM8.00001 3.76606L12.6667 6.43273L8.00001 9.13273L3.33334 6.45939L8.00001 3.76606ZM13.6667 8.95939L8.00001 12.2327L2.33334 8.95939C2.25765 8.91504 2.17395 8.88606 2.08703 8.87412C2.00012 8.86218 1.9117 8.86751 1.82685 8.8898C1.742 8.9121 1.66239 8.95092 1.59257 9.00405C1.52276 9.05718 1.46412 9.12356 1.42001 9.1994C1.37593 9.27548 1.34734 9.35954 1.33588 9.44673C1.32443 9.53391 1.33033 9.6225 1.35326 9.7074C1.37619 9.79229 1.41568 9.87181 1.46947 9.94137C1.52326 10.0109 1.59028 10.0692 1.66667 10.1127L7.66667 13.5794C7.76802 13.6379 7.88298 13.6687 8.00001 13.6687C8.11703 13.6687 8.23199 13.6379 8.33334 13.5794L14.3333 10.1127C14.4097 10.0692 14.4768 10.0109 14.5305 9.94137C14.5843 9.87181 14.6238 9.79229 14.6468 9.7074C14.6697 9.6225 14.6756 9.53391 14.6641 9.44673C14.6527 9.35954 14.6241 9.27548 14.58 9.1994C14.5359 9.12356 14.4773 9.05718 14.4074 9.00405C14.3376 8.95092 14.258 8.9121 14.1732 8.8898C14.0883 8.86751 13.9999 8.86218 13.913 8.87412C13.8261 8.88606 13.7424 8.91504 13.6667 8.95939Z"
      fill={color}
    />
  </svg>
);

export default ComparisonIcon;
