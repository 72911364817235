import React from "react";

const ElectricIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "18px"}
    height={height ? height : "18px"}
    viewBox="0 0 16 17"
    fill={color}
  >
    <path
      d="M13.2599 6.86678C13.2045 6.75683 13.1197 6.66438 13.015 6.59966C12.9103 6.53494 12.7897 6.50048 12.6666 6.50011H9.33324V2.50011C9.3404 2.3539 9.29923 2.2094 9.21609 2.08891C9.13296 1.96842 9.01247 1.87865 8.87324 1.83344C8.73939 1.78941 8.59503 1.78891 8.46088 1.83203C8.32673 1.87515 8.2097 1.95966 8.12657 2.07344L2.79324 9.40678C2.72642 9.50335 2.68629 9.61587 2.67693 9.73294C2.66756 9.85001 2.68929 9.96747 2.73991 10.0734C2.78652 10.1946 2.8675 10.2995 2.9729 10.3753C3.0783 10.4511 3.20354 10.4945 3.33324 10.5001H6.66657V14.5001C6.66668 14.6407 6.71122 14.7776 6.79384 14.8914C6.87645 15.0051 6.99292 15.0899 7.12657 15.1334C7.19356 15.1542 7.26313 15.1654 7.33324 15.1668C7.43843 15.1671 7.54219 15.1424 7.63605 15.0949C7.7299 15.0474 7.81118 14.9784 7.87324 14.8934L13.2066 7.56011C13.2784 7.46064 13.3214 7.34328 13.3308 7.22095C13.3402 7.09862 13.3157 6.97606 13.2599 6.86678ZM7.99991 12.4468V9.83344C7.99991 9.65663 7.92967 9.48706 7.80465 9.36204C7.67962 9.23701 7.51005 9.16678 7.33324 9.16678H4.66657L7.99991 4.55344V7.16678C7.99991 7.34359 8.07015 7.51316 8.19517 7.63818C8.32019 7.76321 8.48976 7.83344 8.66657 7.83344H11.3332L7.99991 12.4468Z"
      fill={color}
    />
  </svg>
);

export default ElectricIcon;
