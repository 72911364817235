import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckedIcon from "../../../../../../CommonComponents/customIcons/CheckedIcon";

const TitlesSelectGroup = ({ titles }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setFieldValue("title", value);
  };
  const theme = useTheme();
  return titles?.map((title) => (
    <Box
      sx={{
        padding: "10px 15px",
        border: `1px solid ${
          values?.title === title
            ? theme.palette.border.brand
            : theme.palette.border.secondary
        } !important`,
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: theme.palette.background.secondaryhover,
        },
      }}
    >
      <FormControlLabel
        key={title}
        control={
          <Checkbox
            value={title}
            checked={values?.title === title}
            onChange={(event) => handleCheckboxChange(event)}
            icon={
              <CheckBoxOutlineBlankIcon sx={{ color: "text.quaternary" }} />
            }
            checkedIcon={<CheckedIcon />}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 30,
              },
            }}
          />
        }
        label={
          <Typography style={{ fontSize: 14 }} color="text.primary">
            {title}
          </Typography>
        }
      />
    </Box>
  ));
};
export default TitlesSelectGroup;
