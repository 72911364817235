import React from "react";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import CustomeTooltip from "./Components/Tooltip/Tooltip";
import { useTheme } from "@mui/material";

const LineChart = ({
  data,
  xaxisDatakey = "xAxis",
  hasXasix,
  hasYaxis,
  hasLegend,
  hasGrid,
  hasTooltip,
  secondaryLinesDataKeys,
  mainArea = "ChatGpt",
  mainAreaGardient = "rgba(255, 255, 255, 0.0001)",
  index,
  height = 117,
  marginTop = 0,
  marginLeft = 0,
  colorMap = {},
  isMainSelected,
  mainAreaColor: mainColor,
}) => {
  const theme = useTheme();
  const secondaryColors = [
    theme.palette.brands.B,
    theme.palette.brands.C,
    theme.palette.brands.D,
    theme.palette.brands.E,
    theme.palette.brands.F,
    theme.palette.brands.G,
  ];

  const mainAreaColor = mainColor || theme.palette.brands.A;
  const strokeColor = theme.palette.border.quaternary;
  const tickColor = theme.palette.text.tertiary;
  return (
    <div
      style={{
        width: "100%",
        height: height,
        marginTop: marginTop,
        marginLeft: marginLeft,
      }}
    >
      <ResponsiveContainer>
        <ComposedChart width={500} data={data}>
          {/* Grid */}
          {hasGrid && <CartesianGrid vertical={false} stroke={strokeColor} />}

          {/* XAxis */}
          {hasXasix && (
            <XAxis
              dataKey={xaxisDatakey}
              axisLine={false}
              tickLine={false}
              tick={{ fill: tickColor, fontSize: 12, fontWeight: 450 }}
            />
          )}

          {/* YAxis */}
          {hasYaxis && (
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{
                fill: tickColor,
                fontSize: 12,
                fontWeight: 450,
              }}
            />
          )}

          {hasTooltip && (
            <Tooltip
              content={<CustomeTooltip />}
              cursor={{
                stroke: "#CCCCCC",
                strokeWidth: 1,
                strokeDasharray: "2 2",
              }}
            />
          )}
          {/* Legend */}
          {hasLegend && <Legend />}

          <defs>
            <linearGradient
              id={`mainGradient${index}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="50.74%" stopColor={mainAreaColor} stopOpacity={1} />
              <stop
                offset="100%"
                stopColor={mainAreaGardient}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          {isMainSelected && (
            <Area
              type="linear"
              dataKey={mainArea}
              stroke={mainAreaColor}
              fill={`url(#mainGradient${index})`}
              strokeWidth={2}
              fillOpacity={0.09}
            />
          )}

          {secondaryLinesDataKeys?.map((datakey, index) => (
            <Line
              key={index}
              type="linear"
              dataKey={datakey}
              stroke={colorMap[datakey]}
              dot={false}
              strokeWidth={2}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
