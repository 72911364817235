import { Box, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import StepperModal from "../../../../CommonComponents/StepperModal";
import Business from "../../../BrandDna/components/BrandDNAForm/components/Business";
import Persona from "../../../BrandDna/components/BrandDNAForm/components/Persona";
import BrandVoice from "../../../BrandDna/components/BrandDNAForm/components/BrandVoice/BrandVoice";
import Competition from "../../../BrandDna/components/BrandDNAForm/components/Competition/Competition";
import { getValidationSchema, steps } from "../../../BrandDna/conts";
import { API_URL } from "../../../../api";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import Global from "../../../BrandDna/components/BrandDNAForm/components/Global";

const useStyles = makeStyles(() => ({
  iconTypography: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    columnGap: 5,
  },
  clickableText: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
const IncompleteDna = ({ isAeo }) => {
  const { brandId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [initialStep] = useState(0);
  const [brandDetails, setBrandDetails] = useState({});
  const companyName = localStorage.getItem("worskapceName") || "Workspace name";
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [isAutoSave, setIsAutoSave] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}`)
      .then((response) => {
        setBrandDetails(response.data);
        localStorage.setItem("worskapceName", response.data.name);
      })
      .catch((err) => {});
  }, [brandId]);

  const getStepContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return <Business brandDetails={brandDetails} />;
        case 1:
          return <Persona brandDetails={brandDetails} />;

        case 2:
          return <BrandVoice brandDetails={brandDetails} />;

        case 3:
          return <Competition brandDetails={brandDetails} />;
        case 4:
          return <Global brandDetails={brandDetails} />;
        default:
          return <Business brandDetails={brandDetails} />;
      }
    },
    [brandDetails]
  );
  const initialValues = useMemo(
    () => ({
      // business
      name: brandDetails?.name,
      primary_product: brandDetails?.primary_product,
      description: brandDetails?.description,
      conversion_goals: brandDetails?.conversion_goals || [],
      industry: brandDetails?.industry || "",
      business_model: brandDetails?.business_model || "",
      features: brandDetails?.features || ["", ""],
      benefits: brandDetails?.benefits || ["", ""],
      market_focus: brandDetails?.market_focus || "",
      usp: brandDetails.usp || "",
      country: brandDetails?.country || "",
      primary_language: brandDetails.primary_language || "",
      website: brandDetails?.website || "",
      additional_websites: brandDetails?.additional_websites || [""],
      secondary_countries: brandDetails?.secondary_countries || [],
      topics: brandDetails?.topics || [""],
      market_attributes: brandDetails?.market_attributes || [""],
      comparison_attributes: brandDetails?.comparison_attributes || [""],
      social_media_accounts_urls: brandDetails?.social_media_accounts_urls || [
        "",
      ],

      // persona
      age_ranges: brandDetails?.age_ranges || [],
      genders: brandDetails?.genders || [],
      interests: brandDetails?.interests || [],
      job_functions: brandDetails?.job_functions || [],
      job_titles: brandDetails?.job_titles || [],
      desires: brandDetails?.desires || ["", ""],
      pain_points: brandDetails?.pain_points || ["", ""],

      // brand voice
      brand_voice: brandDetails?.brand_voice || "",
      not_brand_voice: brandDetails?.not_brand_voice || "",
      formality_tone: brandDetails?.formality_tone || "",
      core_themes: brandDetails?.core_themes || [],
      emotions: brandDetails?.emotions || [],
      styles: brandDetails?.styles || [],

      // competitors
      competitors: brandDetails?.competitors || [{ name: "", website: "" }],
    }),
    // eslint-disable-next-line
    [brandId, brandDetails]
  );
  const handleSave = useCallback(
    (values) => {
      !isAutoSave && setLoading(true);
      axios
        .patch(`${API_URL}/brands/${brandId}`, values, {})
        .then((response) => {
          if (!isAutoSave) {
            setLoading(false);
            window.location.href = `/dashboard/${brandId}/`;
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [brandId, isAutoSave]
  );

  return (
    <box>
      {!isAeo ? (
        <Typography
          sx={{
            backgroundColor: "background.negative",
            padding: "5px 12px",
            fontSize: "14.8px",
            fontWeight: 500,
            borderRadius: "11px",
            mb: 2,
            mt: 3,
          }}
          color="text.negative"
          className={classes.iconTypography}
        >
          <LockOutlinedIcon style={{ fontSize: 18 }} />
          Complete the{" "}
          <span
            className={classes.clickableText}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Brand DNA
          </span>{" "}
          to unlock these functions
        </Typography>
      ) : (
        <Box
          sx={{
            backgroundColor: "background.negative",
            padding: "5px 12px",
            fontSize: "14.8px",
            fontWeight: 500,
            borderRadius: "11px",
          }}
          color="text.negative"
          className={classes.iconTypography}
        >
          <LockOutlinedIcon style={{ fontSize: 18 }} />
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              className={classes.iconTypography}
              sx={{ fontSize: "14.8px", fontWeight: 500 }}
            >
              We need additional information from you to unlock these features,
              please complete the
              <span
                className={classes.clickableText}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Brand DNA
              </span>{" "}
              to unlock these functions.
            </Typography>
            <Typography
              className={classes.iconTypography}
              sx={{ fontSize: "14.8px", fontWeight: 500 }}
            >
              Since we work with live data, it may take up to 48 hours after you
              update the
              <span
                className={classes.clickableText}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Brand DNA
              </span>{" "}
              for the report to become available.
            </Typography>
          </Box>
        </Box>
      )}

      <StepperModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        setOpenModal={setOpenModal}
        modalName="Brand DNA"
        title={companyName}
        steps={steps}
        initialStep={initialStep}
        getStepContent={getStepContent}
        formikChildren={(Business, Persona, BrandVoice, Competition)}
        initialValues={initialValues}
        getValidationSchema={getValidationSchema}
        handleSave={handleSave}
        loading={loading}
        setIsAutoSave={setIsAutoSave}
        isAutoSave={isAutoSave}
      />
    </box>
  );
};
export default IncompleteDna;
