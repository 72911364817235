import { Box, Typography, useTheme } from "@mui/material";

const GeneratingCard = ({ padding = 50, marginTop = 5 }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        backgroundColor: theme.palette.background.primary,
        borderRadius: 5,
        display: "flex",
        justifyContent: "center",
        padding: padding,
        marginTop: marginTop,
      }}
    >
      <Typography
        color="text.primary"
        style={{ fontWeight: 500, fontSize: 14 }}
      >
        Data will populate soon
      </Typography>
    </Box>
  );
};
export default GeneratingCard;
