import React, { useState } from "react";
import {
  Box,
  Typography,
  Snackbar,
  useTheme,
  GlobalStyles,
} from "@mui/material";
import ChipButton from "../../../../CommonComponents/ChipButton/ChipButton";
import PageTemplate from "../../../../CommonComponents/PageTemplate/PageTemplate";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import TurndownService from "turndown";
import { useBlog } from "../../../../contexts/blogContext";
import TextEditor from "../TextEditor/TextEditor";

const FinalBlog = () => {
  const { title } = useParams();
  const [open, setOpen] = useState(false);
  const { editedBlog = "", setEditedBlog } = useBlog();

  const handleCopy = () => {
    const turndownService = new TurndownService();
    const markdown = turndownService.turndown(editedBlog);
    navigator.clipboard
      .writeText(markdown)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  return (
    <>
      <PageTemplate>
        <GlobalStyles
          styles={{
            ".ql-toolbar": {
              backgroundColor: theme.palette.background.secondary,
            },
            ".ql-container": {
              backgroundColor: theme.palette.background.secondary,
            },
            ".ql-editor": {
              backgroundColor: theme.palette.background.secondary,
            },
            ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded ": {
              backgroundColor: theme.palette.background.secondary,
            },
          }}
        />
        <Typography
          variant="h2"
          style={{
            fontWeight: 500,
            fontSize: 40,
            margin: 0,
            padding: 0,
            lineHeight: 1,
            maxWidth: 684,
            marginBottom: 20,
          }}
        >
          {title}
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{ fontSize: 14, fontWeight: 450 }}
            color="text.secondary"
          >
            Use the text editor to customize your blog post.
          </Typography>
          <ChipButton
            iconBefore={<ContentPasteOutlinedIcon />}
            label="Copy to Clipboard"
            backgroundColor={theme.palette.background.secondary}
            textColor={theme.palette.text.secondary}
            padding="10px 10px"
            fontSize="16px"
            borderColor={theme.palette.border.secondary}
            hoverBackgroundColor={theme.palette.background.primaryhover}
            hoverTextColor={theme.palette.text.primary}
            fontWeight={450}
            columnGap={0.5}
            onClick={() => handleCopy()}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0px 85px 70px 85px",
            boxShadow: "none",
            flexDirection: "column",
            paddingTop: 20,
          }}
        >
          <TextEditor
            generatedOutline={editedBlog}
            setEditedBlog={setEditedBlog}
            backgroundColor={theme.palette.background.secondary}
          />
        </Box>
      </PageTemplate>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Blog copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
};

export default FinalBlog;
