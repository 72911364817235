import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../api";
import BarChart from "../../../../CommonComponents/Charts/BarChart";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyCompetitorsData } from "../../consts/consts";
import { reorderTransformedData } from "../../utils/DataUtils";
import ChartCard from "../ChartCard/ChartCard";
import { useTheme } from "@mui/material";

const BarChartsCard = ({
  icon,
  title,
  tooltipPrimary,
  tooltipSecondary,
  height,
  hasLLMSelect,
}) => {
  const theme = useTheme();
  const colors = [
    theme.palette.brands.A,
    theme.palette.brands.B,
    theme.palette.brands.C,
    theme.palette.brands.D,
    theme.palette.brands.E,
    theme.palette.brands.F,
    theme.palette.brands.G,
  ];
  const transformData = (inputArray) => {
    return inputArray.map((item, index) => ({
      brand: item.label,
      value: Math.round(item.score * 100) / 100,
      fill: colors[index] || "#000000",
      backgroundValue: 100 - item.score,
    }));
  };

  const { noData } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(["ChatGpt"]);
  const [visibilityScores, setVisibilityScores] = useState(
    noData ? transformData(dummyCompetitorsData) : []
  );
  const { brandId } = useParams();
  const currentWorkspaceName = localStorage.getItem("worskapceName");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const values = {
      brandId,
      comparsion_type: "Competitors",
      LLMs: selectedLLM,
    };
    setLoading(true);
    axios
      .post(`${ML_AEO_URL}/aeo/brand-visibility-score-rank`, values)
      .then((response) => {
        setLoading(false);
        const data = response.data;
        const orderedData = reorderTransformedData(data, currentWorkspaceName);
        setVisibilityScores(transformData(orderedData));
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }, [brandId, selectedLLM, currentWorkspaceName]);

  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessagePrimary={tooltipPrimary}
      tooltipMessageSecondary={tooltipSecondary}
      height={height}
      hasLLMselect={hasLLMSelect}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      isLoading={loading}
      isError={error}
    >
      <BarChart data={visibilityScores} />
    </ChartCard>
  );
};
export default BarChartsCard;
