import { Divider } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../api";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyStackedData } from "../../consts/consts";
import { spreadSentimentData } from "../../utils/DataUtils";
import ChartCard from "../ChartCard/ChartCard";
import RankingWithStackedBar from "../RankingWithStackedBar/RankingWithStackedBar";
import TitlesGrid from "../TitlesGrid/TitlesGrid";

const RankingStackedBarCard = ({
  icon,
  title,
  tooltipPrimary,
  tooltipSecondary,
  hasLLMSelect,
  height,
  gridTitle2,
  gridTitle3,
}) => {
  const { llm, noData } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [data, setData] = useState(noData ? dummyStackedData : []);
  const { brandId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    const values = { brandId, LLMs: selectedLLM };
    axios
      .post(`${ML_AEO_URL}/aeo/sentiment-competitor`, values)
      .then((response) => {
        setLoading(false);
        const transformedData = spreadSentimentData(response.data);
        setData(transformedData);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }, [brandId, selectedLLM]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);
  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessagePrimary={tooltipPrimary}
      tooltipMessageSecondary={tooltipSecondary}
      hasLLMselect={hasLLMSelect}
      height={height}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      isLoading={loading}
      isError={error}
    >
      <Divider
        sx={{
          height: "1px",
          borderColor: "border.quaternary",
          borderBottomWidth: "1px",
          margin: "20px 0px",
        }}
      />
      <TitlesGrid title2={gridTitle2} title3={gridTitle3} grid1={1} />
      <RankingWithStackedBar rankings={data} />
    </ChartCard>
  );
};
export default RankingStackedBarCard;
