import React from "react";

const GlobalIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "18px"}
    height={height ? height : "18px"}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path
      d="M14.2134 3.36687L10.2134 2.03353H10.1667C10.1357 2.03041 10.1044 2.03041 10.0734 2.03353H9.92004H9.83337H9.78671L6.00004 3.33353L2.21338 2.03353C2.11312 2.00047 2.00645 1.9917 1.90213 2.00792C1.79782 2.02415 1.69885 2.06492 1.61338 2.12687C1.52722 2.18821 1.45689 2.26917 1.4082 2.36307C1.35951 2.45697 1.33387 2.5611 1.33338 2.66687V12.0002C1.33302 12.14 1.37659 12.2763 1.45794 12.39C1.53929 12.5036 1.6543 12.5888 1.78671 12.6335L5.78671 13.9669C5.92101 14.0107 6.06574 14.0107 6.20004 13.9669L10 12.7002L13.7867 14.0002C13.8575 14.0098 13.9293 14.0098 14 14.0002C14.1394 14.0022 14.2755 13.9576 14.3867 13.8735C14.4729 13.8122 14.5432 13.7312 14.5919 13.6373C14.6406 13.5434 14.6662 13.4393 14.6667 13.3335V4.0002C14.6671 3.86044 14.6235 3.7241 14.5421 3.61045C14.4608 3.4968 14.3458 3.41159 14.2134 3.36687ZM5.33338 12.4069L2.66671 11.5202V3.59353L5.33338 4.4802V12.4069ZM9.33337 11.5202L6.66671 12.4069V4.4802L9.33337 3.59353V11.5202ZM13.3334 12.4069L10.6667 11.5202V3.59353L13.3334 4.4802V12.4069Z"
      fill={color}
    />
  </svg>
);

export default GlobalIcon;
