import React from "react";
import { useTheme } from "@mui/material";

const LoginIcon = () => {
  const theme = useTheme();

  const color = theme.palette.background.brand;
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="22.5" fill={color} fill-opacity="0.2" />
      <path
        d="M22.5 19C21.9696 19 21.4609 19.2107 21.0858 19.5858C20.7107 19.9609 20.5 20.4696 20.5 21C20.5021 21.349 20.5955 21.6914 20.771 21.9932C20.9464 22.2949 21.1977 22.5455 21.5 22.72V26C21.5 26.2652 21.6054 26.5196 21.7929 26.7071C21.9804 26.8946 22.2348 27 22.5 27C22.7652 27 23.0196 26.8946 23.2071 26.7071C23.3946 26.5196 23.5 26.2652 23.5 26V22.72C23.8023 22.5455 24.0536 22.2949 24.2291 21.9932C24.4045 21.6914 24.4979 21.349 24.5 21C24.5 20.4696 24.2893 19.9609 23.9142 19.5858C23.5391 19.2107 23.0304 19 22.5 19ZM22.5 13C20.5222 13 18.5888 13.5865 16.9443 14.6853C15.2998 15.7841 14.0181 17.3459 13.2612 19.1732C12.5043 21.0004 12.3063 23.0111 12.6922 24.9509C13.078 26.8907 14.0304 28.6725 15.4289 30.0711C16.8275 31.4696 18.6093 32.422 20.5491 32.8079C22.4889 33.1937 24.4996 32.9957 26.3268 32.2388C28.1541 31.4819 29.7159 30.2002 30.8147 28.5557C31.9135 26.9112 32.5 24.9778 32.5 23C32.5 21.6868 32.2413 20.3864 31.7388 19.1732C31.2363 17.9599 30.4997 16.8575 29.5711 15.9289C28.6425 15.0003 27.5401 14.2638 26.3268 13.7612C25.1136 13.2587 23.8132 13 22.5 13ZM22.5 31C20.9178 31 19.371 30.5308 18.0554 29.6518C16.7398 28.7727 15.7145 27.5233 15.109 26.0615C14.5035 24.5997 14.345 22.9911 14.6537 21.4393C14.9624 19.8874 15.7243 18.462 16.8432 17.3431C17.962 16.2243 19.3874 15.4624 20.9393 15.1537C22.4911 14.845 24.0997 15.0035 25.5615 15.609C27.0233 16.2145 28.2727 17.2398 29.1518 18.5554C30.0308 19.871 30.5 21.4177 30.5 23C30.5 25.1217 29.6572 27.1566 28.1569 28.6569C26.6566 30.1571 24.6217 31 22.5 31Z"
        fill="#5A70C7"
      />
    </svg>
  );
};

export default LoginIcon;
