import axios from "axios";
import { API_URL } from "../api";

export const handleUserLogic = () => {
  const loggedInUser = localStorage.getItem("loggedInUser");
  const userId = loggedInUser
    ? JSON.parse(localStorage.getItem("loggedInUser")).id
    : "";

  if (userId) {
    axios
      .get(`${API_URL}/accounts/${userId}`)
      .then((response) => {
        const userData = response.data;
        localStorage.setItem("loggedInUser", JSON.stringify(userData));
      })
      .catch((error) => {});
  }
};
