import { Step, StepLabel, Stepper, StepIcon, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StepperCompletedIcon from "../customIcons/StepperCompletedIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepIcon-root": {
      color: "transparent",
      border: `1.5px solid ${theme.palette.border.tertiary}`,
      borderRadius: "50%",
      fontSize: 24,
    },
    "& .Mui-active .MuiStepIcon-root": {
      fill: theme.palette.stepper.stepCircle,
      border: "none",
    },
    "& .Mui-completed .MuiStepIcon-root": {
      fill: theme.palette.stepper.stepCircleCompleted,
      border: "none",
    },
    "& .MuiStepIcon-root .MuiStepIcon-text": {
      fill: theme.palette.text.secondary,
    },
    "& .MuiStepIcon-root.Mui-active .MuiStepIcon-text": {
      fill: theme.palette.text.primary,
    },
    "& .MuiStepIcon-root.Mui-completed .MuiSvgIcon-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiStepLabel-label": {
      fontSize: 12.08,
      fontWeight: "600 !important",
    },
    "& .MuiStepLabel-label span": {
      fontWeight: "600 !important",
      color: theme.palette.text.primary,
    },
    "& .Mui-completed .MuiStepLabel-label": {
      color: theme.palette.text.secondary,
    },
  },
}));

const CustomStepIcon = (props) => {
  const { completed } = props;

  if (completed) {
    return <StepperCompletedIcon />;
  }

  return <StepIcon {...props} />;
};

const CustomStepper = ({
  steps,
  activeStep,
  setActiveStep,
  hasClickableSteps,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Stepper
      className={classes.root}
      activeStep={activeStep}
      orientation="vertical"
      connector={null}
    >
      {steps.map((label, index) => (
        <Step
          onClick={() =>
            hasClickableSteps
              ? setActiveStep(index)
              : index < activeStep && setActiveStep(index)
          }
          key={label}
          sx={{
            backgroundColor:
              activeStep === index
                ? theme.palette.stepper.activeBackground
                : "transparent",
            padding: 2,
            paddingLeft: 5,
            borderTop: index === 0 && "1.5px solid",
            borderBottom: "1.5px solid",
            borderColor:
              activeStep === index
                ? theme.palette.stepper.activeBackground
                : theme.palette.border.tertiary,
            cursor: "pointer",
          }}
        >
          <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
export default CustomStepper;
