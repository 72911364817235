export const conversionGoalOptions = [
  "Lead Generation / Request Demo",
  "Account Creation",
  "App Install",
];

export const brandIndustryOptions = [
  "Healthcare",
  "Consumer Tech",
  "B2B",
  "Saas",
];

export const businessModelOptions = ["B2B", "B2C", "B2G"];

export const marketFocusOptions = ["Local", "National", "International"];
