import React from "react";

const DarkIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : "18px"}
    height={height ? height : "18px"}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path
      d="M13.765 8.59939C13.6787 8.52806 13.5741 8.4824 13.4631 8.4676C13.3521 8.4528 13.2392 8.46947 13.1373 8.51569C12.5051 8.80498 11.8177 8.95387 11.1225 8.95212C9.83647 8.95057 8.60319 8.44063 7.69158 7.5335C6.77997 6.62637 6.26397 5.39562 6.25608 4.10959C6.25881 3.70655 6.30897 3.30522 6.40554 2.91391C6.42603 2.80961 6.41835 2.70173 6.38327 2.60139C6.3482 2.50104 6.28701 2.41187 6.20601 2.34304C6.125 2.27422 6.02711 2.22824 5.92242 2.20983C5.81773 2.19143 5.71003 2.20127 5.61041 2.23834C4.67319 2.65977 3.85847 3.31252 3.24281 4.13529C2.62714 4.95805 2.23073 5.92382 2.09082 6.94186C1.9509 7.95991 2.07208 8.99681 2.44296 9.95516C2.81384 10.9135 3.42225 11.7618 4.21104 12.4205C4.99982 13.0791 5.94309 13.5264 6.95223 13.7204C7.96137 13.9144 9.00326 13.8486 9.98001 13.5293C10.9568 13.2101 11.8363 12.6477 12.5361 11.8952C13.2358 11.1426 13.7327 10.2245 13.9802 9.22713C14.0104 9.11294 14.006 8.99238 13.9677 8.88066C13.9294 8.76894 13.8589 8.67107 13.765 8.59939ZM8.08548 12.599C7.08215 12.5919 6.10554 12.2749 5.28936 11.6913C4.47318 11.1077 3.85733 10.2861 3.52614 9.339C3.19494 8.39189 3.16459 7.36556 3.43925 6.40053C3.7139 5.4355 4.28012 4.57896 5.06039 3.94817V4.10959C5.06198 5.71688 5.70117 7.25789 6.8377 8.39442C7.97422 9.53094 9.51523 10.1701 11.1225 10.1717C11.5445 10.1733 11.9655 10.1292 12.378 10.0402C11.9654 10.8207 11.3476 11.4737 10.5911 11.9289C9.83467 12.384 8.9683 12.624 8.08548 12.6229V12.599Z"
      fill={color}
    />
  </svg>
);

export default DarkIcon;
