import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import { API_URL } from "../../../../../../api";
import googleLogo from "../../../../../../assets/images/GoogleLogo.webp";

const getConnectorIcon = {
  "Google Search Console": googleLogo,
  "Google Analytics": googleLogo,
};
const ConnectorRow = ({ account, refetch }) => {
  const [switchToggle, setSwitchToggle] = useState(
    account.status === "connected"
  );
  const [loading, setLoading] = useState();

  const handleSwitchToggle = useCallback(() => {
    setLoading(true);

    const values = { status: !switchToggle ? "connected" : "disconnect" };

    axios
      .patch(`${API_URL}/brands/connector/${account.id}`, values)
      .then((response) => {
        refetch();
        setLoading(false);
        setSwitchToggle((prev) => !prev);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [account, switchToggle, setLoading, setSwitchToggle]);
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: 10,
        alignItems: "center",
      }}
    >
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px !important",
          padding: "5px 16px 5px 16px",
          boxShadow: "none !important",
          rowGap: 10,
          width: "100%",
          backgroundColor: "background.secondary",
        }}
      >
        <Grid item xs={12} md={7}>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 10,
            }}
            color="text.primary"
          >
            <img src={getConnectorIcon[account.type]} width={25} height={25} />{" "}
            {account.type}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography style={{ fontSize: 14, fontWeight: 500 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "14.805px",
                fontWeight: 400,
                lineHeight: "18.506px",
                textAlign: "left",
              }}
              color="text.primary"
            >
              <Avatar
                sx={{
                  width: 20,
                  height: 20,
                  marginRight: "10px",
                  backgroundColor: "background.tertiary",
                  color: "text.secondary",
                  textAlign: "center",
                  fontSize: "11.537px",
                  fontWeight: 400,
                }}
              >
                {account.added_by.first_name.charAt(0)}
              </Avatar>
              <Typography noWrap sx={{ maxWidth: "85px" }}>
                {account.added_by.first_name + " "}
                {account.added_by.last_name.charAt(0)}.
              </Typography>
            </Box>
          </Typography>
        </Grid>{" "}
        <Grid item xs={12} md={2}>
          <Typography
            style={{ fontSize: 12, fontWeight: 500 }}
            color="text.tertiary"
          >
            {format(new Date(account.created_at), "MMMM dd, yyyy")}
          </Typography>
        </Grid>{" "}
        <Grid item xs={12} md={1}>
          <Typography style={{ fontSize: 14, fontWeight: 500 }}>
            {loading ? (
              <CircularProgress
                style={{
                  width: 20,
                  height: 20,
                  color: "#2D333A",
                  marginLeft: 20,
                }}
              />
            ) : (
              <Switch
                checked={switchToggle}
                onChange={handleSwitchToggle}
                inputProps={{ "aria-label": "controlled" }}
              />
            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ConnectorRow;
