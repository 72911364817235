import React from "react";
import {
  Avatar,
  IconButton,
  MenuItem,
  Typography,
  Divider,
  ListItemIcon,
  Box,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Logout } from "@mui/icons-material";
import AuthorStampIcon from "../../../customIcons/AuthorStampIcon";
import Pencil from "../../../customIcons/PencilIcon";
import { useNavigate } from "react-router-dom";

const ProfilePopupStatic = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const name =
    loggedInUser?.first_name + " " + loggedInUser?.last_name || "User";
  const email = loggedInUser?.email || "User";
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear("loggedInUser");
    localStorage.clear("workspace");

    navigate(`/login`);
    handleClose();
  };

  return (
    <Box
      sx={{
        width: "226px",
        padding: "10px 16px",
        backgroundColor: "background.secondary",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          pb: "20px",
        }}
      >
        <Box sx={{ position: "relative", mb: "10px" }}>
          <Avatar
            sx={{
              width: "64px",
              height: "64px",
              fontSize: "36px",
              color: "text.tertiary",
              backgroundColor: "background.quaternary",
            }}
          >
            {name.charAt(0)}
          </Avatar>
          <IconButton
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "background.secondary",
              borderRadius: "50%",
              padding: "4px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Pencil color={theme.palette.text.secondary} />
          </IconButton>
        </Box>

        <Typography
          sx={{
            pb: "5px",
            fontSize: "12px",
            fontWeight: 450,
            lineHeight: "16px",
            color: "text.primary",
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            pb: "5px",
            fontSize: "12px",
            fontWeight: 450,
            lineHeight: "16px",
            color: "text.tertiary",
          }}
        >
          {email}
        </Typography>
      </Box>

      <Divider
        sx={{
          height: "0.5px",
          borderColor: "border.tertiary",
          mr: "8px",
          mb: "8px",
        }}
      />

      <MenuItem
        sx={{
          fontSize: "12px",
          fontWeight: 450,
          lineHeight: "16px",
          color: "text.primary",
        }}
        onClick={() => navigate(`account/author-stamp`)}
      >
        <ListItemIcon>
          <AuthorStampIcon
            sx={{ width: "17px" }}
            color={theme.palette.text.primary}
          />
        </ListItemIcon>
        Author stamp
      </MenuItem>
      <Divider
        sx={{
          height: "0.5px",
          borderColor: "border.tertiary",
        }}
      />
      <MenuItem
        onClick={handleLogout}
        disableRipple
        sx={{
          fontSize: "12px",
          fontWeight: 450,
          lineHeight: "16px",
          color: "text.primary",
        }}
      >
        <ListItemIcon>
          <Logout sx={{ width: "17px", color: theme.palette.text.primary }} />
        </ListItemIcon>
        Log out
      </MenuItem>
    </Box>
  );
};

export default ProfilePopupStatic;
