import React from "react";
import { useTheme } from "@mui/material/styles";

const Wrapper = ({ children, backgroundColor, isfullLength }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: isfullLength ? "100vh" : 430,
        backgroundColor: backgroundColor || theme.palette.background.secondary,
        borderRadius: !isfullLength && "20px",
      }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
