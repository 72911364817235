import { Button } from "@mui/material";
import React from "react";
import googleLogo from "../../../../assets/images/GoogleLogo.webp";

const GoogleLoginButton = () => {
  const handleLogin = () => {
    const fullUrl = window.location.href;
    const baseUrl = new URL(fullUrl).origin + "/";
    const redirectTo = encodeURIComponent(baseUrl);

    window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=379648750205-3v7jni15jhc4hh997nsm9887oi569iih.apps.googleusercontent.com&redirect_uri=https://goodie-backend-6de69aeda4e2.herokuapp.com/accounts/auth/google/callback/&response_type=code&scope=profile email&state=${redirectTo}`;
  };

  return (
    <Button
      onClick={handleLogin}
      sx={{
        fontFamily: "Roboto",
        textTransform: "none",
        display: "flex",
        flexDirection: "row",
        columnGap: 1,
        borderRadius: "11px",
        color: "text.tertiary",
        fontSize: 20,
        borderColor: "border.tertiary",
        "&:hover": {
          borderColor: "border.tertiary",
          backgroundColor: "transparent",
        },
        "&.Mui-focused": {
          borderColor: "border.tertiary",
        },
      }}
      fullWidth
      variant="outlined"
      startIcon={
        <img src={googleLogo} alt="google logo" width={30} height={30} />
      }
    >
      Continue with Google
    </Button>
  );
};

export default GoogleLoginButton;
