import FormField from "../../../../../../../CommonComponents/FormField";
import InputSection from "../../InputSection/InputSection";
import {
  coreThemesOptions,
  toneEmotionOptions,
  toneFormalityOptions,
  toneStyleOptions,
} from "./consts";

const BrandVoiceForm = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 30 }}>
      <InputSection
        title="Describe your Brand Voice"
        description="Provide a detailed explanation of how your brand communicates with its audience. Consider the tone, style, and personality traits that define your voice, ensuring consistency across all channels."
        rowGap={10}
        children={
          <FormField
            type="input"
            name={`brand_voice`}
            variant="outlined"
            placeholder="Provide a detailed explanation of how your brand communicates with its audience. Consider the tone, style, and personality traits that define your voice, ensuring consistency across all channels."
            customizedStyling={{ width: "90%" }}
            multiline
            errorMessage
          />
        }
      />
      <InputSection
        title="Describe what isn't your Brand Voice"
        description="Clarify the communication styles, tones, or traits that do not align with your brand. This will help establish boundaries and ensure your messaging remains true to your brand identity."
        rowGap={10}
        children={
          <FormField
            type="input"
            name={`not_brand_voice`}
            variant="outlined"
            placeholder="Clarify the communication styles, tones, or traits that do not align with your brand. This will help establish boundaries and ensure your messaging remains true to your brand identity."
            customizedStyling={{ width: "90%" }}
            multiline
          />
        }
      />
      <InputSection
        title="Tone"
        required
        rowGap={10}
        description="Choose the level of formality that best represents how your brand communicates with its audience."
        children={
          <FormField
            name="formality_tone"
            type="choiceChipsGroup"
            options={toneFormalityOptions}
          />
        }
      />
      <InputSection
        title="Emotion"
        required
        description="Pick at least 1 emotional tone you want your blog content to convey."
        rowGap={10}
        children={
          <FormField
            name="emotions"
            type="dynamicChoiceChipsGroup"
            options={toneEmotionOptions}
            multiSelect
          />
        }
      />{" "}
      <InputSection
        title="Style"
        required
        description="Decide on the writing style that aligns with your brand's voice."
        rowGap={10}
        children={
          <FormField
            name="styles"
            type="dynamicChoiceChipsGroup"
            options={toneStyleOptions}
            multiSelect
          />
        }
      />
      <InputSection
        title="Core Themes"
        description="Identify the main topicss or themes that will guide your blog content."
        rowGap={10}
        children={
          <FormField
            name="core_themes"
            type="dynamicChoiceChipsGroup"
            options={coreThemesOptions}
            multiSelect
          />
        }
      />
    </div>
  );
};
export default BrandVoiceForm;
