import React, { useCallback } from "react";
import { Box, Button, useTheme } from "@mui/material";

const ChipGroup = ({ options, name, field, form, multiSelect, isDynamic }) => {
  const { value } = field;
  const { setFieldValue, setFieldTouched } = form;

  const customOptions = value
    ? isDynamic
      ? multiSelect
        ? Array.from(new Set([...options, ...value]))
        : Array.from(new Set([...options, value]))
      : options
    : options;

  const handleChipClick = useCallback(
    (option) => {
      setFieldTouched(name, true);

      if (multiSelect) {
        const newValue = value?.includes(option)
          ? value.filter((item) => item !== option)
          : [...value, option];
        setFieldValue(name, newValue);
      } else {
        setFieldValue(name, option);
      }
    },
    [name, value, multiSelect, setFieldValue, setFieldTouched]
  );
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      {customOptions.map((option) => (
        <Button
          key={option}
          onClick={() => handleChipClick(option)}
          sx={{
            textTransform: "none",
            padding: "10px 11px 10px 11px",
            backgroundColor: multiSelect
              ? value?.includes(option)
                ? theme.palette.buttons.selected
                : "transparent"
              : value === option
              ? theme.palette.buttons.selected
              : "transparent",
            color: multiSelect
              ? value?.includes(option)
                ? theme.palette.text.primary
                : theme.palette.text.secondary
              : value === option
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
            borderRadius: "11px",
            border: `1px solid ${
              multiSelect
                ? value?.includes(option)
                  ? theme.palette.buttons.selected
                  : theme.palette.border.tertiary
                : value === option
                ? theme.palette.buttons.selected
                : theme.palette.border.tertiary
            }`,
            "&:hover": {
              backgroundColor: multiSelect
                ? value?.includes(option)
                  ? theme.palette.buttons.selected
                  : theme.palette.buttons.secondaryhover
                : value === option
                ? theme.palette.buttons.selected
                : theme.palette.buttons.secondaryhover,
              borderColor: multiSelect
                ? value?.includes(option)
                  ? theme.palette.buttons.selected
                  : theme.palette.border.tertiary
                : value === option
                ? theme.palette.buttons.selected
                : theme.palette.border.tertiary,
            },
          }}
        >
          {option}
        </Button>
      ))}
    </Box>
  );
};

export default ChipGroup;
