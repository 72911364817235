import React from "react";
import { useTheme } from "@mui/material";

const StepperCompletedIcon = ({ color, pathcolor }) => {
  const theme = useTheme();
  const circleColor = color || theme.palette.stepper.stepCircleCompleted;
  const pathColor = pathcolor || theme.palette.text.primary;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <circle cx="11.8968" cy="12.4999" r="11.8968" fill={circleColor} />
      <path
        d="M16.8278 8.96919C16.7671 8.90909 16.6949 8.8614 16.6153 8.82885C16.5358 8.7963 16.4505 8.77954 16.3643 8.77954C16.2781 8.77954 16.1928 8.7963 16.1132 8.82885C16.0337 8.8614 15.9615 8.90909 15.9008 8.96919L11.0373 13.752L8.99392 11.7389C8.93091 11.6791 8.85652 11.6321 8.77502 11.6005C8.69351 11.569 8.60647 11.5535 8.51887 11.555C8.43128 11.5565 8.34484 11.5749 8.26449 11.6092C8.18414 11.6435 8.11146 11.693 8.05059 11.7549C7.98972 11.8168 7.94186 11.8898 7.90974 11.9699C7.87762 12.0499 7.86187 12.1354 7.86338 12.2214C7.8649 12.3074 7.88365 12.3923 7.91857 12.4712C7.95349 12.5501 8.0039 12.6215 8.06691 12.6813L10.5737 15.1432C10.6344 15.2033 10.7066 15.251 10.7862 15.2836C10.8657 15.3161 10.9511 15.3329 11.0373 15.3329C11.1234 15.3329 11.2088 15.3161 11.2883 15.2836C11.3679 15.251 11.4401 15.2033 11.5008 15.1432L16.8278 9.91164C16.894 9.8516 16.9469 9.77874 16.9831 9.69764C17.0193 9.61653 17.0379 9.52895 17.0379 9.44041C17.0379 9.35187 17.0193 9.26429 16.9831 9.18319C16.9469 9.10209 16.894 9.02922 16.8278 8.96919Z"
        fill={pathColor}
      />
    </svg>
  );
};
export default StepperCompletedIcon;
