import { Box, Typography } from "@mui/material";

const InputSection = ({ title, description, children, required }) => {
  return (
    <Box style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
      <Typography
        variant="h4"
        sx={{ fontSize: 16, fontWeight: 600 }}
        color="text.primary"
      >
        {title} {required && <span style={{ color: "#718CF9" }}>*</span>}
      </Typography>
      {description && (
        <Typography
          variant="body1"
          sx={{ fontSize: 14, fontWeight: 450 }}
          color="text.secondary"
        >
          {description}
        </Typography>
      )}
      {children}
    </Box>
  );
};
export default InputSection;
