import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../api";
import RadarChart from "../../../../CommonComponents/Charts/RadarChart";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyRadarData } from "../../consts/consts";
import { reorderKeys } from "../../utils/DataUtils";
import BrandsList from "../BrandsList";
import ChartCard from "../ChartCard/ChartCard";

export function transformToRadarData(inputData) {
  const labels = [];
  const marketKeys = new Set();

  const radarData = inputData?.map((item) => {
    const { label, market_performance } = item;
    labels.push(label);

    Object.keys(market_performance).forEach((key) => marketKeys.add(key));

    const radarItem = {
      benchmark: label,
      ...market_performance,
    };

    return radarItem;
  });

  const marketPerformanceKeys = Array.from(marketKeys);

  return {
    radarData,
    labels,
    marketPerformanceKeys,
  };
}

const RadarChartCard = ({
  title,
  tooltipPrimary,
  tooltipSecondary,
  hasLLMselect,
  height,
  icon,
  comparsion_type = "Competitors",
  llmIsMultipleSelect,
}) => {
  const theme = useTheme();
  const { llm, noData } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState([
    "ChatGpt",
    "Gemini",
    "Perplexity",
  ]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [initialBrands, setInititalBrands] = useState([]);
  const [data, setData] = useState([]);
  const { brandId } = useParams();
  const currentWorkspaceName = localStorage.getItem("worskapceName");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    comparsion_type === "Competitors" && setSelectedLLM(llm);
  }, [llm, comparsion_type]);

  useEffect(() => {
    const values = {
      brandId,
      LLMs: selectedLLM,
      comparsion_type,
    };

    setLoading(true);

    axios
      .post(`${ML_AEO_URL}/aeo/market-performance`, values)
      .then((response) => {
        const data = response.data;

        const transformedData = transformToRadarData(data);

        const reorderedKeys = reorderKeys(
          transformedData.marketPerformanceKeys,
          currentWorkspaceName
        );

        setData(transformedData.radarData);
        setInititalBrands(reorderedKeys);
        setSelectedBrands(reorderedKeys);
      })
      .catch((error) => {
        console.error("Error fetching market performance data:", error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [brandId, selectedLLM, comparsion_type]);

  const colors = [
    theme.palette.brands.A,
    theme.palette.brands.B,
    theme.palette.brands.C,
    theme.palette.brands.D,
    theme.palette.brands.E,
    theme.palette.brands.F,
    theme.palette.brands.G,
  ];
  const colorMap = useMemo(() => {
    const map = {};
    initialBrands.forEach((brand, index) => {
      map[brand] = colors[index % colors.length];
    });
    return map;
  }, [initialBrands]);

  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessagePrimary={tooltipPrimary}
      tooltipMessageSecondary={tooltipSecondary}
      hasLLMselect={hasLLMselect}
      height={height}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      llmIsMultipleSelect={llmIsMultipleSelect}
      isLoading={loading}
      isError={error}
    >
      {noData ? (
        <>
          <RadarChart
            data={dummyRadarData}
            radarTitles={["NoGood", "Competitor1", "Competitor2"]}
            isLLM={comparsion_type === "LLMs"}
          />
          <BrandsList
            brands={["NoGood", "Competitor1", "Competitor2"]}
            isLLM={comparsion_type === "LLMs"}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
          />{" "}
        </>
      ) : (
        data.length > 0 && (
          <>
            <RadarChart
              data={data}
              radarTitles={selectedBrands}
              isLLM={comparsion_type === "LLMs"}
              colorMap={colorMap}
            />
            <BrandsList
              brands={reorderKeys(initialBrands, currentWorkspaceName)}
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
              isLLM={comparsion_type === "LLMs"}
              colorMap={colorMap}
            />
          </>
        )
      )}
    </ChartCard>
  );
};
export default RadarChartCard;
