import { API_URL } from "../../../../../../api";
import axios from "axios";
import ChipButton from "../../../../../../CommonComponents/ChipButton/ChipButton";
import EastIcon from "@mui/icons-material/East";
import { useState } from "react";
import { Card, TextField, Typography, useTheme, Button } from "@mui/material";
import Wrapper from "../../../Wrapper/Wrapper";
import ErrorTypography from "../../../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const AddWorkspace = ({
  setCurrentStep,
  setBrandName: SetSavedBrandName,
  handleClose,
  hasClose,
}) => {
  const userId = localStorage.getItem("userId");
  const [brandName, setBrandName] = useState("");
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (event) => {
    setBrandName(event.target.value);
  };

  const handleNext = () => {
    const values = { name: brandName, users: [userId] };
    setIsLoading(true);
    axios
      .post(`${API_URL}/brands/`, values)
      .then((response) => {
        setIsLoading(false);

        localStorage.setItem("brandId", response.data.brand.id);
        setCurrentStep(6);
        SetSavedBrandName(response.data.brand.name);
        axios
          .get(`${API_URL}/accounts/${userId}`)
          .then((response) => {
            const userData = response.data;
            localStorage.setItem("loggedInUser", JSON.stringify(userData));
          })
          .catch((error) => {});
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <Wrapper>
      <Card
        style={{
          borderRadius: "35px",
          padding: "20px 85px 20px 85px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          width: 550,
          rowGap: 20,
          backgroundColor: "transparent",
          alignItems: "center",
          position: "relative",
        }}
      >
        {hasClose && (
          <Button
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 1,
              right: 5,
              color: "#000",
              fontSize: "20px",
            }}
          >
            <CloseOutlinedIcon />
          </Button>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            rowGap: 16,
          }}
        >
          <Typography
            style={{ fontSize: 36, fontWeight: 500, lineHeight: "44px" }}
            color="text.primary"
          >
            What’s a good name for your workspace?
          </Typography>
          <div>
            <Typography
              style={{ fontSize: 14, fontWeight: 450 }}
              color="text.secondary"
            >
              This will be the name of your Goodie workspace.
            </Typography>{" "}
            <Typography
              style={{ fontSize: 14, fontWeight: 450 }}
              color="text.secondary"
            >
              Choose something that your team will recognize.
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 5,
            width: "100%",
          }}
        >
          <Typography style={{ fontSize: 15 }}>Workspace name</Typography>
          <TextField
            value={brandName}
            onChange={handleChange}
            placeholder="Company"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid",
                  borderColor: `${theme.palette.border.secondary} !important`,
                  borderRadius: "11px",
                },
                "&:hover fieldset": {
                  border: "1px solid",
                  borderColor: theme.palette.border.secondary,
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: theme.palette.border.secondary,
                },
              },
              ".MuiInputAdornment-root": {
                "& fieldset": {
                  border: "1px solid",
                  borderColor: theme.palette.border.secondary,
                  borderRadius: "11px",
                },
                color: theme.palette.text.secondary,
              },
            }}
          />
        </div>
        {error && <ErrorTypography errorMessage={error} />}
        <ChipButton
          iconAfter={<EastIcon />}
          label={"Continue"}
          backgroundColor={theme.palette.background.brand}
          textColor={theme.palette.text.chiptext}
          hoverBackgroundColor={theme.palette.text.brandbold}
          hoverTextColor={theme.palette.text.chiptext}
          hoverBorderColor={theme.palette.text.brandbold}
          fullWidth
          onClick={() => handleNext()}
          isLoading={isLoading}
        />
      </Card>{" "}
    </Wrapper>
  );
};
export default AddWorkspace;
