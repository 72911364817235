import DNAStep from "../Step/Step";
import BusinessForm from "./Form/BusinessForm";

const Business = ({ brandDetails }) => {
  return (
    <DNAStep
      title="Business"
      subtitle="Describe your business and its goals to set the foundation for your content. By answering these questions, you're training Goodie to understand the core of your company, which will result in content that aligns more closely with your business objectives and unique value proposition."
      required
      children={<BusinessForm brandDetails={brandDetails} />}
    />
  );
};
export default Business;
