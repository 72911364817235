import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Paper, GlobalStyles, useTheme } from "@mui/material";
import CustomToolbar from "./components/customToolbar";
import { marked } from "marked";

const TextEditor = ({
  generatedOutline,
  setEditedBlog,
  width,
  backgroundColor = "backgrouns.primary",
}) => {
  const quillRef = useRef(null);
  const theme = useTheme();
  const [editorContent, setEditorContent] = useState("");

  useEffect(() => {
    if (generatedOutline) {
      const htmlContent = marked(generatedOutline);
      setEditorContent(htmlContent);
      setEditedBlog(generatedOutline);
    }
  }, [generatedOutline]);

  const handleEditorChange = (content) => {
    setEditorContent(content);
    setEditedBlog(content);
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  return (
    <Box
      sx={{
        width: width || "100%",
        height: "auto",
        boxSizing: "border-box",
        borderRadius: "11px",
      }}
    >
      <GlobalStyles
        styles={{
          ".ql-toolbar.ql-snow": {
            border: "none",
            boxSizing: "border-box",
            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            padding: "8px",
          },
          ".ql-container.ql-snow": {
            border: "none !important",
          },
          ".ql-snow.ql-toolbar button:hover": {
            color: theme.palette.text.secondary,
          },
        }}
      />
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          height: "auto",
          padding: "10px",
          boxSizing: "border-box",
          borderRadius: "11px",
          backgroundColor: backgroundColor,
          marginBottom: "20px",
          boxShadow: "none",
          border: "none",
        }}
      >
        <CustomToolbar quillRef={quillRef} />
        <ReactQuill
          ref={quillRef}
          value={editorContent}
          onChange={handleEditorChange}
          modules={modules}
          theme="snow"
          style={{
            border: "none",
            borderTop: `1px solid ${theme.palette.border.tertiary}`,
          }}
          editorProps={{
            style: {
              border: "none",
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default TextEditor;
