import { Button } from "@mui/material";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  addMoreButton: {
    borderRadius: "8.25px !important",

    fontWeight: 400,
    marginTop: "15px !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      boxShadow: "none !important",
    },
    padding: "6.75px 7.5px 6.75px 7.5px !important",
    fontSize: 14,
  },
}));

const AddMoreButton = ({ onClick, title = "Add more", showIcon = true }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.addMoreButton}
      onClick={onClick}
      sx={{
        backgroundColor: "background.tertiary",
        color: "text.secondary",
        "&:hover": {
          backgroundColor: "background.tertiary",
          color: "text.primary",
        },
      }}
    >
      {showIcon && (
        <AddCircleOutlineSharpIcon
          sx={{ marginRight: "5px", fontSize: "16px" }}
        />
      )}
      {title}
    </Button>
  );
};
export default AddMoreButton;
