import React from "react";

const Pencil = ({ color = "#2D333A" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      d="M16.7584 5.72977C16.759 5.63107 16.7395 5.53323 16.7008 5.44186C16.6622 5.35049 16.6053 5.26738 16.5334 5.19731L13.2438 2.01755C13.1713 1.94804 13.0853 1.89305 12.9908 1.85573C12.8963 1.81841 12.795 1.79949 12.6929 1.80006C12.5908 1.79949 12.4896 1.81841 12.3951 1.85573C12.3005 1.89305 12.2146 1.94804 12.1421 2.01755L9.94638 4.13989L1.46622 12.3368C1.39432 12.4068 1.33743 12.49 1.29882 12.5813C1.2602 12.6727 1.24063 12.7705 1.24122 12.8692V16.049C1.24122 16.2479 1.32297 16.4386 1.46847 16.5793C1.61397 16.7199 1.81131 16.7989 2.01708 16.7989H5.30673C5.4153 16.8046 5.52389 16.7882 5.62547 16.7508C5.72705 16.7133 5.81935 16.6556 5.89639 16.5815L14.33 8.38457L16.5334 6.29973C16.6043 6.22704 16.662 6.14339 16.7041 6.05224C16.7116 5.99247 16.7116 5.93203 16.7041 5.87226C16.7078 5.83735 16.7078 5.80217 16.7041 5.76726L16.7584 5.72977ZM4.98863 15.2991H2.79295V13.1767L10.4972 5.72977L12.6929 7.85211L4.98863 15.2991ZM13.7869 6.79469L11.5912 4.67235L12.6929 3.61493L14.8809 5.72977L13.7869 6.79469Z"
      fill={color}
    />
  </svg>
);

export default Pencil;
