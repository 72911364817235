import ChipButton from "../../../../CommonComponents/ChipButton/ChipButton";
import CredentialsSection from "../CredentialsSection/CredentialsSection";
import GoogleLogin from "../GoogleLogin";
import OrSeperator from "../OrSeperator/OrSeperator";
import EastIcon from "@mui/icons-material/East";
import { useMemo, useState } from "react";
import { Card, Typography, useTheme } from "@mui/material";
import Wrapper from "../Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import axios from "axios";
import { API_URL } from "../../../../api";
import ErrorTypography from "../../../../CommonComponents/FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";
import MicrosoftLogin from "../MicrosoftLogin";
import LoginIcon from "../../../../CommonComponents/customIcons/LoginIcon";

const Login = () => {
  const [cardTitle, setCardTitle] = useState("Log in to Goodie");
  const [buttonText, setButtonText] = useState("Continue with email");
  const [showPasswordTextfield, setShowPasswordTextfield] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = (values) => {
    setError("");
    if (buttonText === "Sign in") {
      setIsLoading(true);
      const data = { email: values.email, password: values.password };
      axios
        .post(`${API_URL}/accounts/login`, data)
        .then((response) => {
          setIsLoading(false);

          const data = response.data;
          const user_data = data.user;
          const brandId = user_data.brands[0].id;
          localStorage.setItem("loggedInUser", JSON.stringify(user_data));
          localStorage.setItem("brandId", brandId);

          navigate(`/dashboard/${brandId}/`);
        })
        .catch((err) => {
          setIsLoading(false);
          setError("Invalid Email or Password!");
        });
    } else {
      setCardTitle("Enter your password");
      setShowPasswordTextfield(true);
      setButtonText("Sign in");
    }
  };
  const initialValues = useMemo(() => ({}), []);
  const theme = useTheme();

  return (
    <Wrapper isfullLength>
      <Formik initialValues={initialValues}>
        {({ values, errors, dirty }) => (
          <Card
            style={{
              borderRadius: "35px",
              padding: "50px 85px 70px 85px",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              width: 600,
              rowGap: 20,
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {showPasswordTextfield && <LoginIcon />}
              <Typography
                style={{ fontSize: 36, fontWeight: 600 }}
                color="text.primary"
              >
                {cardTitle}
              </Typography>
            </div>

            <CredentialsSection showPasswordTextfield={showPasswordTextfield} />
            {error && <ErrorTypography errorMessage={error} />}
            <ChipButton
              iconAfter={!showPasswordTextfield && <EastIcon />}
              label={buttonText}
              backgroundColor={theme.palette.background.brand}
              textColor={theme.palette.text.chiptext}
              hoverBackgroundColor={theme.palette.text.brandbold}
              hoverTextColor={theme.palette.text.chiptext}
              hoverBorderColor={theme.palette.text.brandbold}
              fullWidth
              onClick={() => handleOnClick(values)}
              isLoading={isLoading}
            />

            <Typography style={{ fontSize: 14 }} color="text.secondary">
              Don’t have an account?{" "}
              <span
                style={{
                  color: theme.palette.text.brandbold,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/signup")}
              >
                Sign up
              </span>
            </Typography>
            {!showPasswordTextfield && (
              <>
                <OrSeperator />
                <GoogleLogin />
                {/* <MicrosoftLogin /> */}
              </>
            )}
          </Card>
        )}
      </Formik>
    </Wrapper>
  );
};
export default Login;
