import LineChart from "../../../../CommonComponents/Charts/LineChart";
import ChartCard from "../ChartCard/ChartCard";
import BrandsList from "../BrandsList";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { ML_AEO_URL } from "../../../../api";
import { useParams } from "react-router-dom";
import {
  reorderKeys,
  transformCompetitorsLineChartData,
  transformLLMLineChartData,
} from "../../utils/DataUtils";
import { Typography, useTheme } from "@mui/material";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import { dummyLineData } from "../../consts/consts";

const LineChartCard = ({
  title,
  tooltipPrimary,
  tooltipSecondary,
  hasLLMselect,
  hasCaledarSelect,
  height,
  chartHeight,
  chartHasTooltip,
  chartHasGrid,
  chartHasXasix,
  chartHasYaxis,
  chartHasMarginTop,
  chartHasMarginLeft,
  icon,
  isLLMComparison,
  hasBrandLabelOnly,
  isGlobal,
  isMainSelected,
}) => {
  const {
    date: globalDate,
    llm,
    initialDate: globalInitialDate,
    selectedCountries,
  } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const { brandId } = useParams();
  const [data, setData] = useState([]);
  const currentWorkspaceName = localStorage.getItem("worskapceName");
  const [initialBrands, setInititalBrands] = useState([]);
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const { noData } = useGlobalFilters();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = (url, values) => {
      setLoading(true);
      axios
        .post(url, values)
        .then((response) => {
          const data = response.data;

          const filteredData = isLLMComparison
            ? transformLLMLineChartData(data)
            : transformCompetitorsLineChartData(data, selectedLLM[0]);

          setData(filteredData.transformedData);
          setInititalBrands(filteredData?.keys);
          if (!isLLMComparison) {
            setSelectedBrands(filteredData?.keys);
          }
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (date.length > 0 && date[0] && date[1]) {
      const commonValues = {
        brandId,
        start_date: date[0].format("YYYY-MM-DD"),
        end_date: date[1].format("YYYY-MM-DD"),
        LLMs: selectedLLM,
      };

      if (!isGlobal) {
        const comparisonType = isLLMComparison ? "LLMs" : "Competitors";
        const values = {
          ...commonValues,
          comparsion_type: comparisonType,
        };

        fetchData(
          `${ML_AEO_URL}/aeo/visibility-performance-line-chart`,
          values
        );
      } else {
        const values = {
          ...commonValues,
          countries: selectedCountries,
        };

        fetchData(`${ML_AEO_URL}/aeo/global-performance-line-chart`, values);
      }
    }
  }, [
    brandId,
    selectedLLM,
    isLLMComparison,
    date,
    isGlobal,
    selectedCountries,
  ]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    setInitialDate(globalInitialDate);
  }, [globalInitialDate]);
  const theme = useTheme();

  const colorMap = useMemo(() => {
    const colors = [
      theme.palette.brands.A,
      theme.palette.brands.B,
      theme.palette.brands.C,
      theme.palette.brands.D,
      theme.palette.brands.E,
      theme.palette.brands.F,
      theme.palette.brands.G,
    ];
    const brands = reorderKeys(initialBrands, currentWorkspaceName);
    const map = {};
    brands.forEach((brand, index) => {
      map[brand] = colors[index % colors.length];
    });
    return map;
  }, [initialBrands]);
  return (
    <ChartCard
      icon={icon}
      title={title}
      tooltipMessagePrimary={tooltipPrimary}
      tooltipMessageSecondary={tooltipSecondary}
      hasCalendarSelect={hasCaledarSelect}
      hasLLMselect={hasLLMselect}
      height={height}
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
      isLoading={loading}
      isError={error}
    >
      <LineChart
        data={noData ? dummyLineData : data}
        xaxisDatakey="xAxis"
        hasXasix={chartHasXasix}
        hasYaxis={chartHasYaxis}
        height={chartHeight}
        hasTooltip={chartHasTooltip}
        marginTop={chartHasMarginTop}
        marginLeft={chartHasMarginLeft}
        hasGrid={chartHasGrid}
        mainArea={
          noData
            ? "NoGood"
            : isLLMComparison
            ? selectedLLM[0]
            : currentWorkspaceName
        }
        secondaryLinesDataKeys={selectedBrands.filter(
          (key) => key !== currentWorkspaceName
        )}
        isMainSelected={
          isMainSelected ||
          hasBrandLabelOnly ||
          selectedBrands.filter((key) => key === currentWorkspaceName).length >
            0
        }
        colorMap={colorMap}
      />
      {!isLLMComparison && (
        <BrandsList
          brands={reorderKeys(initialBrands, currentWorkspaceName)}
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
          colorMap={colorMap}
        />
      )}
      {hasBrandLabelOnly && (
        <Typography
          style={{
            display: "inline-block",
            border: `1px solid ${theme.palette.brands.A}`,
            padding: "10px 12px",
            fontSize: 14,
            borderRadius: 11,
            cursor: "pointer",
            backgroundColor: theme.palette.brands.A,
          }}
          color="text.chiptext"
        >
          {currentWorkspaceName}
        </Typography>
      )}
    </ChartCard>
  );
};
export default LineChartCard;
