import { Box, Typography, useTheme } from "@mui/material";

const InputSection = ({
  title,
  description,
  children,
  rowGap = 20,
  width = 830,
  required,
  optinalLabel,
}) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: rowGap,
        width: width,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
        <Box style={{ display: "flex", flexDirection: "row", columnGap: 10 }}>
          <Typography
            variant="h4"
            component="h4"
            style={{
              fontSize: 18,
              fontWeight: 500,
            }}
          >
            {title} {required && <span style={{ color: "#718CF9" }}>*</span>}
          </Typography>
          {optinalLabel && (
            <Typography
              style={{
                backgroundColor: theme.palette.background.tertiary,
                fontSize: 12,
                fontWeight: 400,
                padding: "3px 8px",
                borderRadius: 30,
              }}
              color="text.secondary"
            >
              {optinalLabel}
            </Typography>
          )}
        </Box>

        {description && (
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 450,
              whiteSpace: "pre-wrap",
              textAlign: "left",
              overflowWrap: "break-word",
              padding: 0,
              margin: 0,
            }}
            color="text.secondary"
          >
            {description}
          </Typography>
        )}
      </div>
      {children}
    </Box>
  );
};
export default InputSection;
