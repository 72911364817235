import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckedIcon from "../../../customIcons/CheckedIcon";

const SingleCheckBox = ({ name, header, subHeader, disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: "10px 15px",
        border: `1px solid ${
          values[name]
            ? theme.palette.border.brand
            : theme.palette.border.secondary
        } !important`,
        borderRadius: "8px",
        "&:hover": {
          background: theme.palette.background.secondaryhover,
        },
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={values[name]}
            onChange={(event) => setFieldValue(name, event.target.checked)}
            disabled={disabled}
            icon={
              <CheckBoxOutlineBlankIcon
                sx={{
                  color: disabled ? "background.quaternary" : "text.primary",
                }}
              />
            }
            checkedIcon={<CheckedIcon />}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 30,
              },
            }}
          />
        }
        label={
          <Typography
            sx={{
              fontSize: 14,
              color: disabled ? "text.quaternary" : "text.primary",
            }}
          >
            {header}{" "}
            <Typography
              component="span"
              sx={{
                color: disabled ? "text.quaternary" : "text.tertiary",
              }}
            >
              {subHeader}
            </Typography>
          </Typography>
        }
      />
    </Box>
  );
};
export default SingleCheckBox;
