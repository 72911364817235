import { Divider, Typography } from "@mui/material";

const OrSeperator = () => {
  return (
    <Divider
      sx={{
        width: "100%",
        my: 1,
        "&::before, &::after": {
          borderColor: "border.tertiary",
        },
      }}
    >
      <Typography variant="body2" sx={{ px: 2 }} color="text.secondary">
        OR
      </Typography>
    </Divider>
  );
};
export default OrSeperator;
