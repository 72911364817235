import React from "react";
import { Box, Typography } from "@mui/material";
import { forwardRef } from "react";

const WorkspaceImage = forwardRef(
  ({ isCollapsed, inTooltip, name, image, ...props }, ref) =>
    image ? (
      <img
        ref={ref}
        src={image}
        width={24}
        height={24}
        style={{
          marginRight: inTooltip ? "12px" : isCollapsed ? "0px" : "8px",
          marginBottom: inTooltip ? "0px" : isCollapsed ? "12px" : "0px",
        }}
        {...props}
      />
    ) : (
      <Typography
        ref={ref}
        sx={{
          width: 27,
          height: 27,
          backgroundColor: "#F5F5F5",
          color: "#0D0D0D",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: 500,
          fontSize: 15,
          mr: inTooltip ? "12px" : isCollapsed ? "0px" : "8px",
          mb: inTooltip ? "0px" : isCollapsed ? "12px" : "0px",
        }}
        {...props}
      >
        {name?.charAt(0).toUpperCase()}
      </Typography>
    )
);

export default WorkspaceImage;
