import React from "react";
import { useTheme } from "@mui/material";

const EditIcon = ({ color, width = "18px", height = "18px" }) => {
  const theme = useTheme();

  const Color = theme.palette.text.tertiary || color || "#AFB1B7";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.9997 8.00012C13.8229 8.00012 13.6533 8.07036 13.5283 8.19538C13.4032 8.32041 13.333 8.48998 13.333 8.66679V12.6668C13.333 12.8436 13.2628 13.0132 13.1377 13.1382C13.0127 13.2632 12.8432 13.3335 12.6663 13.3335H3.33301C3.1562 13.3335 2.98663 13.2632 2.8616 13.1382C2.73658 13.0132 2.66634 12.8436 2.66634 12.6668V3.33346C2.66634 3.15664 2.73658 2.98708 2.8616 2.86205C2.98663 2.73703 3.1562 2.66679 3.33301 2.66679H7.33301C7.50982 2.66679 7.67939 2.59655 7.80441 2.47153C7.92944 2.3465 7.99967 2.17693 7.99967 2.00012C7.99967 1.82331 7.92944 1.65374 7.80441 1.52872C7.67939 1.40369 7.50982 1.33346 7.33301 1.33346H3.33301C2.80257 1.33346 2.29387 1.54417 1.91879 1.91924C1.54372 2.29431 1.33301 2.80302 1.33301 3.33346V12.6668C1.33301 13.1972 1.54372 13.7059 1.91879 14.081C2.29387 14.4561 2.80257 14.6668 3.33301 14.6668H12.6663C13.1968 14.6668 13.7055 14.4561 14.0806 14.081C14.4556 13.7059 14.6663 13.1972 14.6663 12.6668V8.66679C14.6663 8.48998 14.5961 8.32041 14.4711 8.19538C14.3461 8.07036 14.1765 8.00012 13.9997 8.00012ZM3.99967 8.50679V11.3335C3.99967 11.5103 4.06991 11.6798 4.19494 11.8049C4.31996 11.9299 4.48953 12.0001 4.66634 12.0001H7.49301C7.58075 12.0006 7.66772 11.9838 7.74895 11.9506C7.83017 11.9175 7.90405 11.8686 7.96634 11.8068L12.5797 7.18679L14.473 5.33346C14.5355 5.27148 14.5851 5.19775 14.6189 5.11651C14.6528 5.03527 14.6702 4.94813 14.6702 4.86012C14.6702 4.77211 14.6528 4.68498 14.6189 4.60374C14.5851 4.5225 14.5355 4.44876 14.473 4.38679L11.6463 1.52679C11.5844 1.4643 11.5106 1.41471 11.4294 1.38086C11.3482 1.34702 11.261 1.32959 11.173 1.32959C11.085 1.32959 10.9979 1.34702 10.9166 1.38086C10.8354 1.41471 10.7616 1.4643 10.6997 1.52679L8.81967 3.41346L4.19301 8.03346C4.13122 8.09575 4.08234 8.16963 4.04916 8.25085C4.01598 8.33208 3.99917 8.41905 3.99967 8.50679V8.50679ZM11.173 2.94012L13.0597 4.82679L12.113 5.77346L10.2263 3.88679L11.173 2.94012ZM5.33301 8.78012L9.28634 4.82679L11.173 6.71346L7.21967 10.6668H5.33301V8.78012Z"
        fill={Color}
      />
    </svg>
  );
};

export default EditIcon;
