import ChartCard from "../../../../components/ChartCard/ChartCard";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { useEffect, useState } from "react";
import GeneratingCard from "../../../../components/GeneratingCard/GeneratingCard";
import GlobalIcon from "../../../../../../CommonComponents/customIcons/GlobalIcon";
import { useTheme } from "@mui/material";

const VisibilityAcrossLanguages = () => {
  const { llm } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);
  const theme = useTheme();

  return (
    <ChartCard
      icon={<GlobalIcon color={theme.palette.text.primary} />}
      title="Visibility Across Languages"
      tooltipMessagePrimary="Shows your brand’s visibility score across different languages and selected LLMs. Higher scores indicate stronger brand presence"
      height={254}
      hasLLMselect
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
    >
      <GeneratingCard padding={70} marginTop={20} />
    </ChartCard>
  );
};
export default VisibilityAcrossLanguages;
