import React from "react";

const Loader = ({ addOverlay }) => {
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
  };

  const loaderStyle = {
    border: "16px solid #f3f3f3",
    borderTop: "16px solid #C7F565",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    animation: "spin 2s linear infinite",
  };

  return (
    <div style={addOverlay && overlayStyle}>
      <div style={loaderStyle}></div>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default Loader;
