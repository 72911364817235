import { Grid, useTheme } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import LineChartCard from "../../components/LineChartCard/LineChartCard";
import RankingWheelsCard from "../../components/RankingWheelsCard/RankingWheelsCard";
import RankingProgressCard from "../../components/RankingProgressCard/RankingProgressCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";

const VisibilityAnalysis = () => {
  const theme = useTheme();
  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="Visibility Analysis"
        description="View brand visibility metrics across platforms and models, tracking scores, trends, and performance over time to assess changes and act quickly."
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={6.8}>
            <RankingProgressCard
              icon={<VisibilityIcon color={theme.palette.text.primary} />}
              title="Performance Score"
              tooltipPrimary="Displays your brand's visibility score across competitors. Higher scores indicate a stronger position compared to competitors."
              tooltipSecondary="All scores are out of 100."
              height={266}
              hasTypographyRanking="1st"
              gridTitle2="Company"
            />
          </Grid>
          <Grid item xs={5}>
            <RankingWheelsCard
              icon={<VisibilityIcon color={theme.palette.text.primary} />}
              title="Ranking Across LLM Models"
              tooltipPrimary="Shows brand visibility score by individual LLM models. Higher scores indicate greater brand prominence within that model."
              tooltipSecondary="All scores are out of 100."
              height={266}
              wheelsGrid={4}
            />
          </Grid>{" "}
        </Grid>

        <LineChartCard
          icon={<VisibilityIcon color={theme.palette.text.primary} />}
          title="Brand Visibility Over Time"
          tooltipPrimary="Track your brand’s visibility trend over time across selected LLMs."
          hasCaledarSelect
          hasLLMselect
          height={328}
          chartHasXasix
          chartHasYaxis
          chartHeight={200}
          chartHasTooltip
          chartHasMarginTop={20}
          chartHasMarginLeft={-20}
          chartHasGrid
          isLLMComparison
          hasBrandLabelOnly
        />
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default VisibilityAnalysis;
