import { Avatar, Box, Button, Grid, Modal, Typography } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../../../api";
import RolesDropdown from "../RolesDropdown";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useState } from "react";
import InvitedUsersRow from "./Components/InvitedUsersRow";
import InactiveUserRow from "./Components/InactiveUserRow/InactiveUserRow";

const AllPeopleList = ({
  peopleList,
  refetch,
  invitedUsers,
  inActiveUserEmails,
}) => {
  const { brandId } = useParams();
  const [confirmDelete, setConfirmDelete] = useState();
  const [currentPerson, setCurrentPerson] = useState({});
  const companyName = localStorage.getItem("worskapceName");

  const handleDropdownChange = (event, person) => {
    const value = event.target.value;
    let is_admin = false;
    if (value === "admin") is_admin = true;

    const values = {
      email: person.email,
      brand_id: brandId,
      is_admin: is_admin,
    };
    axios
      .post(`${API_URL}/brands/workspace-admin/manage`, values)
      .then((response) => {
        refetch();
      })
      .catch((err) => {});
  };
  const removePerson = () => {
    axios
      .delete(`${API_URL}/brands/${brandId}/remove-user/${currentPerson.id}`)
      .then((response) => {
        setConfirmDelete(false);

        refetch();
      })
      .catch((err) => {});
  };

  return (
    <Box style={{ marginBottom: 50 }}>
      <Grid container>
        <Grid item xs={12} md={10.8}>
          <Typography
            style={{ fontSize: 14, fontWeight: 500, lineHeight: "16px" }}
            color="text.primary"
          >
            Name
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography
            style={{ fontSize: 14, fontWeight: 500, lineHeight: "16px" }}
            color="text.primary"
          >
            Access
          </Typography>
        </Grid>
      </Grid>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 10,
          marginTop: 24,
        }}
      >
        {peopleList.map((person) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: 10,
              alignItems: "center",
            }}
          >
            <Grid
              container
              sx={{
                borderRadius: "10px !important",
                padding: "12px 16px 12px 16px",
                boxShadow: "none !important",
                rowGap: 10,
                width: "100%",
                backgroundColor: "background.secondary",
              }}
            >
              <Grid item xs={12} md={11}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: 10,
                    alignItems: "center",
                    fontSize: 14.8,
                  }}
                >
                  {person.first_name ? (
                    <>
                      <Avatar
                        sx={{
                          width: 20,
                          height: 20,
                          fontSize: 11,
                          color: "text.secondary",
                          backgroundColor: "background.tertiary",
                        }}
                      >
                        {person.first_name.charAt(0).toUpperCase()}
                      </Avatar>
                      {person.first_name} {person.last_name}
                    </>
                  ) : (
                    <>
                      {" "}
                      <Avatar
                        sx={{
                          width: 20,
                          height: 20,
                          fontSize: 11,
                          color: "text.secondary",
                          backgroundColor: "background.tertiary",
                        }}
                      >
                        {person.email.charAt(0).toUpperCase()}
                      </Avatar>
                      {person.email}
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={1}>
                <RolesDropdown
                  dropdownValue={person.is_admin ? "admin" : "author"}
                  handleDropdownChange={(e) => handleDropdownChange(e, person)}
                />
              </Grid>
            </Grid>
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                setCurrentPerson(person);
                setConfirmDelete(true);
              }}
              style={{
                fontSize: 25,
                color: "#797979",
                cursor: "pointer",
              }}
            />
          </Box>
        ))}
        {invitedUsers?.map((invite) => (
          <InvitedUsersRow invite={invite} refetch={refetch} />
        ))}

        {inActiveUserEmails?.map((email) => (
          <InactiveUserRow email={email} refetch={refetch} />
        ))}
      </Box>
      <Modal
        open={confirmDelete}
        onClose={() => {
          setConfirmDelete(false);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: 10,
            boxShadow: 24,
            outline: "none",
            padding: 5,
          }}
        >
          <Typography style={{ fontWeight: 400, fontSize: 20 }}>
            Are you sure you want to remove{" "}
            <span style={{ fontWeight: 600 }}>{currentPerson.email} </span>
            from {companyName}'s workspace?
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: 10,
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ color: "#141414", backgroundColor: "#F4F4F5" }}
              onClick={() => {
                setConfirmDelete(false);
              }}
            >
              Close
            </Button>
            <Button
              style={{ color: "#E9301C", backgroundColor: "#FEF3F2" }}
              onClick={() => {
                removePerson();
              }}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default AllPeopleList;
