import PageTemplate from "../../../../CommonComponents/PageTemplate/PageTemplate";
import { Typography } from "@mui/material";

const WorkspacePageTemplate = ({ title, children, navigateTo }) => {
  return (
    <PageTemplate>
      {title && (
        <Typography
          style={{ fontSize: 36, fontWeight: 500 }}
          sx={{ mt: { xs: 2, md: navigateTo ? 4 : 1 }, mb: { xs: 2, md: 5 } }}
          color="text.primary"
        >
          {title}
        </Typography>
      )}
      {children}
    </PageTemplate>
  );
};
export default WorkspacePageTemplate;
