import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../api";

const Accounts = () => {
  const { userId } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    localStorage.setItem("userId", userId);
    axios
      .get(`${API_URL}/accounts/${userId}`)
      .then((response) => {
        const userData = response.data;
        const userId = userData.id;

        localStorage.setItem("loggedInUser", JSON.stringify(userData));
        localStorage.setItem("userId", userId);

        if (userData.brands?.length > 0) {
          const brandId = userData.brands[0].id;
          localStorage.setItem("brandId", brandId);
          navigate(`/dashboard/${brandId}/`);
        } else if (userData.pending_invites?.length > 0) {
          navigate(`/accounts/${userId}/pending-invites`);
        } else {
          navigate("/workspace-setup");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data");
        setLoading(false);
      });
  }, [userId]);
  return;
};
export default Accounts;
