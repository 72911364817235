import React from "react";
import { Box, Card, Typography, useTheme } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ChipButton from "../../../../../../CommonComponents/ChipButton/ChipButton";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

const ReferenceCard = ({ reference, onSelect, isSelected }) => {
  const { title, url, date, source } = reference;
  const theme = useTheme();
  return (
    <Card
      onClick={() => onSelect(reference)}
      sx={{
        backgroundColor: "transparent",
        "&:hover": {
          borderColor: isSelected ? "border.brand" : "border.primary",
        },
        padding: "15px 30px 16px 15px",
        borderRadius: "10px",
        border: "1px solid",
        borderColor: isSelected ? "border.brand" : "border.tertiary",
        display: "flex",
        flexDirection: "column",
        rowGap: 1.4,
        cursor: "pointer",
        boxShadow: "none",
        minHeight: 160,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ fontSize: 12, fontWeight: 450 }}
          color="text.secondary"
          variant="h5"
        >
          {source}
        </Typography>

        <ChipButton
          label="Selected"
          backgroundColor={
            isSelected ? theme.palette.background.brand20 : "transparent"
          }
          textColor={isSelected ? theme.palette.text.brandbold : "transparent"}
          hoverBackgroundColor={
            isSelected ? theme.palette.background.brand20 : "transparent"
          }
          iconBefore={
            isSelected && (
              <CheckCircleOutlinedIcon style={{ fontSize: "15px" }} />
            )
          }
          padding="0px 8px"
          fontSize="11.78px"
          fontWeight={450}
          columnGap={0.5}
        />
      </Box>

      <Typography
        style={{
          fontSize: 14,
          fontWeight: 500,
          maxWidth: 218,
        }}
        variant="h4"
        color="text.primary"
      >
        {title}
      </Typography>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "auto",
        }}
      >
        <Typography style={{ fontSize: 12 }} color="text.secondary">
          {date}
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            color: "text.secondary",
            "&:hover": {
              color: "text.primary",
            },
            display: "flex",
            flexDirection: "row",
            columnGap: 0.5,
            alignItems: "center",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(url, "_blank");
          }}
        >
          <OpenInNewOutlinedIcon style={{ fontSize: 12 }} /> Read more
        </Typography>
      </Box>
    </Card>
  );
};
export default ReferenceCard;
