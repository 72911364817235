import { useState } from "react";
import Wrapper from "../Wrapper/Wrapper";
import AddWorkspace from "./Components/AddWorkspace/AddWorkspace";
import InviteUsers from "./Components/InviteUsers/InviteUsers";
import Setup from "./Components/Setup/Setup";
import WelcomeDNA from "./Components/WelcomeDNA/WeclomeDNA";

const AccountWorkspace = () => {
  const [currentStep, setCurrentStep] = useState(4);
  const [brandName, setBrandName] = useState("");
  return (
    <Wrapper isfullLength>
      {currentStep === 1 && <Setup setCurrentStep={setCurrentStep} />}
      {currentStep === 4 && (
        <AddWorkspace
          setCurrentStep={setCurrentStep}
          setBrandName={setBrandName}
        />
      )}
      {/* {currentStep === 5 && (
        <InviteUsers setCurrentStep={setCurrentStep} brandName={brandName} />
      )} */}

      {currentStep === 6 && <WelcomeDNA setCurrentStep={setCurrentStep} />}
    </Wrapper>
  );
};
export default AccountWorkspace;
