import { Box, Typography } from "@mui/material";
import ChipButton from "../../CommonComponents/ChipButton";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";

const NotFound404 = () => {
  const navigate = useNavigate();
  const brandId = localStorage.getItem("brandId");

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 25,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#4D4D4D",
        color: "#FFFFFF",
        height: "100vh",
      }}
    >
      <Typography
        variant="h2"
        style={{
          fontSize: 40,
          fontWeight: 500,
        }}
      >
        Error 404 Page
      </Typography>
      <Typography
        variant="h3"
        style={{
          fontSize: 22,
          fontWeight: 450,
        }}
      >
        The page you are looking for cannot be found.
      </Typography>
      <ChipButton
        iconBefore={<WestIcon />}
        onClick={() => navigate(`/dashboard/${brandId}`)}
        label="Back to Home"
        backgroundColor="transparent"
        textColor="#FFFFFF"
        hoverBackgroundColor="#E1E1E1"
        hoverTextColor="#FFFFFF"
        hoverBorderColor="#E1E1E1"
        padding="5px 15px"
        borderColor="#FFFFFF"
      />
    </Box>
  );
};
export default NotFound404;
